/* eslint-disable */
import { ProductConfigurableAttributes as SourceProductConfigurableAttributes } from 'HoeksComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';
import ProductConfigurableAttributeDropdown from 'Component/ProductConfigurableAttributeDropdown';

// import new extended styles file
import './ProductConfigurableAttributes.override.style.scss';

/** @namespace Component/ProductConfigurableAttributes/Component */
export class ProductConfigurableAttributes extends SourceProductConfigurableAttributes {
    renderDropdown(option) {
        const {
            updateConfigurableVariant,
            getIsConfigurableAttributeAvailable,
            parameters,
            variants,
            hasSpecialPrice,
            special_from_date,
            special_to_date,
            attributes,
            variantAttributes,
            product
        } = this.props;

        return (
            <ProductConfigurableAttributeDropdown
                option={option}
                updateConfigurableVariant={updateConfigurableVariant}
                getIsConfigurableAttributeAvailable={getIsConfigurableAttributeAvailable}
                parameters={parameters}
                product={product}
                variants={variants}
                attributes={attributes}
                variantAttributes={variantAttributes}
                hasSpecialPrice={hasSpecialPrice}
                special_from_date={special_from_date}
                special_to_date={special_to_date}
            />
        );
    }

    renderConfigurableAttributes() {
        const result = super.renderConfigurableAttributes();

        if (!result) {
            return result;
        }

        const {
            areVariantsLoaded
        } = this.props;

        if (Array.isArray(result)) {
            const updatedChildren = React.Children.map(result, (child) => React.isValidElement(child) ? React.cloneElement(child, { isLoading: !areVariantsLoaded }) : child);

            return updatedChildren;
        }

        return result;
    }
}

export default ProductConfigurableAttributes;