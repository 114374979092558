/* eslint-disable */

// imports the original component from vendor folder
import HoeksBlogPostCard from 'scandipwa-blog/src/component/BlogPostCard/BlogPostCard.component';

//import dependencies
import Link from 'Component/Link';
import { getPostUrl } from 'scandipwa-blog/src/util/Posts';
import Fade from 'react-reveal/Fade';

// import new extended styles file
import './BlogPostCard.override.style.scss';

export class BlogPostCard extends HoeksBlogPostCard { 

    renderTitle() {
        const { post: { title }, post } = this.props;
        if (!title) {
            return null;
        }

        return (
        <Link to={ getPostUrl(post) }>
                <h4 block="BlogPostCard" elem="TextTitle">
                    { title }
                </h4>
        </Link>
        );
    }

    renderContents() {
        const { isPlaceholder } = this.props;

        if (isPlaceholder) {
            return null;
        }

        return (
            <Fade bottom small duration={500}>
                <div block="BlogPostCard" elem="ContentWrapper" mods={ { expand: true } }>
                    <div block="BlogPostCard" elem="InnerWrapper" >
                        { this.renderImage() }
                        <div block="BlogPostCard" elem="Details">
                            { this.renderTitle() }
                            <div block="BlogPostCard" elem="DescriptionWrapper">
                                { this.renderDescription() }
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        );
    }


    render() {
        const { isPlaceholder, isCompactOnMobile } = this.props;

        const {
            post: {
                meta_keywords
            }
        } = this.props;

        var keywords = '';
        var isFeatured = false;
        if (meta_keywords) {
            var keywords = meta_keywords ? meta_keywords.split(',') : null;
            var isFeatured = keywords.includes('featured');
        };
        
        return (
            <div block="BlogPostCard" mods={ { isLoading: isPlaceholder, isCompactOnMobile, isFeatured } }>
                { this.renderContents() }
            </div>
        );
    }
    
}

export default BlogPostCard;
