import ProductLinks from 'SourceComponent/ProductLinks/ProductLinks.component';
import ContentWrapper from 'SourceComponent/ContentWrapper';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './HelloRetail.style.scss';

export class HelloRetailRecommendationBox extends ProductLinks {

    renderHeading() {
        const { title } = this.props;

        return (
            <h3 block="HelloRetail" elem="Title">
                { title }
            </h3>
        );
    }

    render() {
        const { areDetailsLoaded } = this.props;
        if (!areDetailsLoaded) {
            return null;
        }

        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }             
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }             
                }
            ]
        };

        return (
            <ContentWrapper
              mix={ { block: 'HelloRetail' } }
              wrapperMix={ { block: 'HelloRetail', elem: 'Wrapper' } }
              label={ __('Linked products') }
            >
                { this.renderHeading() }
                <ul block="HelloRetail" elem="Slider">
                <Slider {...settings}>
                    { this.renderItems() }
                </Slider>
                </ul>
            </ContentWrapper>
        );
    }
}

export default HelloRetailRecommendationBox;