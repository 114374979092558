/* eslint-disable */
import CategoryBlock from '../component/CategoryBlock';
import CategoryBlockPlaceholder from '../component/CategoryBlockPlaceholder';
export const CATEGORY_BLOCK_TYPE = 'CategoryBlock'
const addCategoryBlockWidget = (member, instance) => {
    return {
        ...member,
        [CATEGORY_BLOCK_TYPE]: {
            component: CategoryBlock,
            fallback: () => <CategoryBlockPlaceholder />
        }
    }
}

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': addCategoryBlockWidget
        }
    },
}