/* eslint-disable */
import HoeksMenuOverlayComponent from 'HoeksComponent/MenuOverlay/MenuOverlay.component';
import Overlay from 'Component/Overlay';
import Link from 'Component/Link';
import Menu from 'SourceComponent/Menu/Menu.component';
import { getSortedItems } from 'Util/Menu';
import MenuItem from 'Component/MenuItem';
import HelloRetailSearch from '@hoeks/helloretail/src/component/HelloRetailSearch';

// import new extended style file
import './MenuOverlay.override.style.scss';

/** @namespace Component/MenuOverlay/Component */
export class MenuOverlayComponent extends HoeksMenuOverlayComponent {
    componentDidMount() {}

    componentWillUnmount() {}

    renderSubLevelItems = (item) => {
        const {
            handleSubcategoryClick,
            activeMenuItemsStack,
            onCategoryHover,
            closeMenu,
            device
        } = this.props;

        const {
            item_id,
            children,
            item_class,
            title
        } = item;

        const isBanner = item_class === 'Menu-ItemFigure_type_banner';
        const isHideOnDesktop = item_class === 'Menu-ItemFigure_type_hideOnDesktop';
        const mods = {
            isBanner: !!isBanner,
            isHideOnDesktop: !!isHideOnDesktop
        };
        // console.log('debug', item)
        const childrenArray = Object.values(children);
        const subcategoryMods = { type: 'subcategory' };

        if (childrenArray.length && device.isMobile && title != "DESIGNERE A-Z" && title != "BRANDS A-Z") {
            return (
                <div
                  key={ item_id }
                  // TODO: split into smaller components
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleSubcategoryClick(e, item) }
                  tabIndex="0"
                  role="button"
                >
                    <MenuItem
                      activeMenuItemsStack={ activeMenuItemsStack }
                      item={ item }
                      itemMods={ subcategoryMods }
                      onCategoryHover={ onCategoryHover }
                      closeMenu={ closeMenu }
                    />
                    { this.renderSubLevel(item) }
                </div>
            );
        }

        return (
            <div
              block="Menu"
              elem="SubItemWrapper"
              key={ item_id }
              mods={ mods }
            >
                <MenuItem
                  activeMenuItemsStack={ activeMenuItemsStack }
                  item={ item }
                  closeMenu={ closeMenu }
                  isLink
                />
                { this.renderDesktopSubLevel(item) }
            </div>
        );
    };

    renderNav() {
        const { activeMenuItemsStack, menu, goBack } = this.props;
        const [mainMenu] = Object.values(menu);
        const isVisible = !!activeMenuItemsStack.length;
        const currentItem = activeMenuItemsStack.slice().reduceRight((acc, itemId) => {
            const {
                children: {
                    [itemId]: currentItem
                }
            } = acc;

            return currentItem;
        }, mainMenu) ?? {};

        const { title } = currentItem;

        const { item_id } = currentItem;

        return (
            <nav block="Header" elem="Nav" mods={{ item_id }}>
                <button
                    block="Header"
                    elem="Button"
                    mods={{ type: 'back', isVisible }}
                    onClick={goBack}
                    aria-label="Go back"
                    aria-hidden={!isVisible}
                    tabIndex={isVisible ? 0 : -1}></button>
                <p block="Header" elem="Title" mods={{ isVisible }}>{title}</p>
                {this.renderSearchField()}
            </nav>
        );
    }

    renderUsps() {
        return (
            <>
                <div
                    block="MenuOverlay"
                    elem="Fader"
                >
                </div>
                <div
                block="MenuOverlay"
                elem="Usps"
                >
                    <Link 
                        block="MenuOverlay"
                        elem="Usp"
                        mods={{ 'type': 'delivery' }}
                        to="/fragt-levering"
                    >
                        <div
                            block="MenuOverlay"
                            elem="UspIcon"
                        />
                        <span
                            block="MenuOverlay"
                            elem="UspText"
                        >
                            { __('Free delivery in DK') }
                        </span>
                    </Link>
                    <Link  
                        block="MenuOverlay"
                        elem="Usp"
                        mods={{ 'type': 'emaerket' }}
                        to="https://certifikat.emaerket.dk/ingvardchristensen.dk"
                        target="_blank"
                    >
                        <div
                            block="MenuOverlay"
                            elem="UspIcon"
                        />
                        <span
                            block="MenuOverlay"
                            elem="UspText"
                        >
                            { __('E-maerket certified') }
                        </span>
                    </Link>
                    <Link 
                        block="MenuOverlay"
                        elem="Usp"
                        mods={{ 'type': 'facebook' }}
                        to="https://www.facebook.com/IngvardChristensen.dk"
                        target="_blank"
                    >
                        <div
                            block="MenuOverlay"
                            elem="UspIcon"
                        />
                        <span
                            block="MenuOverlay"
                            elem="UspText"
                        >
                            { __('Visit on Facebook') }
                        </span>
                    </Link>
                    <Link  
                        block="MenuOverlay"
                        elem="Usp"
                        mods={{ 'type': 'instagram' }}
                        to="https://www.instagram.com/ingvard_christensen/"
                        target="_blank"
                    >
                        <div
                            block="MenuOverlay"
                            elem="UspIcon"
                        />
                        <span
                            block="MenuOverlay"
                            elem="UspText"
                        >
                            { __('Visit on Instagram') }
                        </span>
                    </Link>
                </div>
            </>
        );
    }

    renderTopLevel() {
        const { menu } = this.props;
        const categoryArray = Object.values(menu);

        if (!categoryArray.length) {
            return null;
        }

        const [{ children, title: mainCategoriesTitle }] = categoryArray;
        const childrenArray = getSortedItems(Object.values(children));

        return (
            <>
                {this.renderNav()}
                <div block="Menu" elem="MainCategories">
                    <ul
                        block="Menu"
                        elem="ItemList"
                        mods={{ type: 'main' }}
                        aria-label={mainCategoriesTitle}
                    >
                        {childrenArray.map(this.renderFirstLevel)}
                    </ul>
                    {this.renderAdditionalInformation(true)}
                </div>
                {this.renderUsps()}
                {this.renderSubMenuDesktop(children)}
            </>
        );
    }

    renderSearchField() {
        const { activeMenuItemsStack } = this.props;
        const isVisible = !activeMenuItemsStack.length;
        return <HelloRetailSearch isVisible={isVisible} overlay={true} />;
    }

    render() {
        const { isActive } = this.props;

        return (
            <Overlay
              id="menu"
              mix={ { block: 'MenuOverlay', mods: { isActive } } }
            >
                <article
                  block="MenuOverlay"
                  elem="Results"
                  aria-label="Search results"
                >
                    { Menu.prototype.render.call(this) }
                </article>
            </Overlay>
        );
    }
}

export default MenuOverlayComponent;
