/* eslint-disable */

// imports the original component from vendor folder
import SearchItem from 'Component/SearchItem/SearchItem.component';

import TextPlaceholder from 'Component/TextPlaceholder';

import '@hoeks/clerk/src/component/ClerkSearchItem/ClerkSearchItem.override.style.scss'
import { formatPrice, roundPrice } from 'Util/Price';
import Image from 'Component/Image';

export class ClerkSearchItem extends SearchItem {
    renderProductPrice() {
        const {
            product: {
                product_type,
                price_range: { minimum_price: { final_price: { currency, value: price } } }
            } = {}
        } = this.props;

        if (product_type === 'configurable') {
            return <TextPlaceholder content={__('From %s', formatPrice(price, currency))} length="short" />;
        }

        return <TextPlaceholder content={formatPrice(price, currency)} length="short" />;
    }

    renderContent() {
        const { product: { name, product_type, purchase_by_contact, price_range: { minimum_price: { final_price: { currency, value: price } } } } } = this.props;

        return (
            <figcaption block="SearchItem" elem="Content">
                {this.renderCustomAttribute()}
                <h4 block="SearchItem" elem="Title" mods={{ isLoaded: !!name }}>
                    <TextPlaceholder content={name} length="long" />
                </h4>
                {
                    purchase_by_contact == "Ja" ? <></> :
                    <h6 block="SearchItem" elem="Price">
                        { this.renderProductPrice() }
                    </h6>
                }
            </figcaption>
        );
    }

    renderImage() {
        const {
            product: { name, id },
            imgSrc,
            thumbnailItems = []
        } = this.props;

        const {
            thumbnail: {
                url = imgSrc
            } = {}
        } = thumbnailItems.find(({ id: thumbnailId }) => thumbnailId == id) || {};

        if (name && !url) {
            return (
                <div
                  block="SearchItem"
                  elem="Image"
                />
            );
        }

        return (
            <Image
              block="SearchItem"
              elem="Image"
              src={ url }
              alt={ __('Product %s thumbnail.', name) }
              isPlaceholder={ !name }
            />
        );
    }

}

export default ClerkSearchItem;
