/* eslint-disable */
const updateUrlRewrite = (args, callback, context) => {
    const [ urlRewrite, ...rest ] = args;
    const {
        id = null
    } = urlRewrite;
    if (id && typeof id === 'string') {
        urlRewrite.id = parseInt(id);
        return callback.apply(context, [urlRewrite, ...rest]);
    }
    return callback.apply(context, args);
}

export default {
    'Store/UrlRewrites/Action/updateUrlRewrite': {
        'function': updateUrlRewrite
    }
}