/* eslint-disable */
import ClerkSearch from '../component/ClerkSearch';
import ClerkProductList from '../component/ClerkProductList';

const aroundRenderSearchField = (args, callback, instance) => {
    return <><ClerkSearch key="clerksearch"/>{callback(...args)}</>;
}
const aroundRenderSearchFieldOverlay = (args, callback, instance) => {
    const { activeMenuItemsStack } = instance.props;
    const isVisible = !activeMenuItemsStack.length;
    return <><ClerkSearch isVisible={isVisible} overlay={true} />{callback(...args)}</>;
}
const aroundGetIsMatchingListFilter = (args, callback, instance) => {
    return true;
}
const aroundRenderCategoryProductList = (args, callback, instance) => {
    const {
        filter,
        search,
        selectedSort,
        selectedFilters,
        isMatchingListFilter,
        isCurrentCategoryLoaded,
        isMatchingInfoFilter
    } = instance.props;

    const { layout } = instance.state;

    if (!instance.displayProducts()) {
        return null;
    }

    return (
        <div block="CategoryPage" elem="ProductListWrapper">
            { instance.renderItemsCount(true) }
            <ClerkProductList
              filter={ filter }
              search={ search }
              sort={ selectedSort }
              selectedFilters={ selectedFilters }
              isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
              isMatchingListFilter={ isMatchingListFilter }
              isMatchingInfoFilter={ isMatchingInfoFilter }
              layout={ layout }
            />
        </div>
    );
}

export default {
    // 'Component/Header/Component': {
    //     'member-function': {
    //         'renderSearchField': aroundRenderSearchField
    //     }
    // },
    // 'Component/MenuOverlay/Component': {
    //     'member-function': {
    //         'renderSearchField': aroundRenderSearchFieldOverlay
    //     }
    // },
    // 'Route/SearchPage/Component': {
    //     'member-function': {
    //         'renderCategoryProductList': aroundRenderCategoryProductList,
    //         'renderFilterOverlay': () => null
    //     }
    // },
    // 'Route/SearchPage/Container': {
    //     'member-function': {
    //         'getIsMatchingListFilter': aroundGetIsMatchingListFilter,
    //         'getIsMatchingInfoFilter': aroundGetIsMatchingListFilter
    //     }
    // }
}