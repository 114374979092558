/* eslint-disable */
import { ProductConfigurableAttributesPopupContainer as SourceProductConfigurableAttributesPopupContainer, mapStateToProps, mapDispatchToProps } from '@hoeks/simple-configurable-product/src/component/ProductConfigurableAttributesPopup/ProductConfigurableAttributesPopup.container';
import { connect } from 'react-redux';
import { productHasSpecialPrice } from 'Util/Product';
export * from '@hoeks/simple-configurable-product/src/component/ProductConfigurableAttributesPopup/ProductConfigurableAttributesPopup.container';

/** @namespace Component/ProductConfigurableAttributesPopup/Container */
export class ProductConfigurableAttributesPopupContainer extends SourceProductConfigurableAttributesPopupContainer {
    filterDiscount(product) {
        const {
            saerligt_god_pris
        } = product;
        return productHasSpecialPrice(product) || (saerligt_god_pris && saerligt_god_pris != 'Nej');
    }

    getCustomFilters() {
        return {
            ...super.getCustomFilters(),
            discount: {
                filter: this.filterDiscount,
                label: __('Show only discount and IC price')
            }
        };
    }

    getSort() {
        return (a, b) => {
            const aDiscount = this.filterDiscount(a);
            const bDiscount = this.filterDiscount(b);

            if (aDiscount && bDiscount) {
                return 0;
            }
            if (aDiscount && !bDiscount) {
                return -1;
            }
            if (!aDiscount && bDiscount) {
                return 1;
            }
            return 0;
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductConfigurableAttributesPopupContainer);