import { PureComponent } from 'react';
// import HelloRetailBox from '../HelloRetailRecommendationBox';
import HelloRetailRecommendationBox from '../HelloRetailRecommendationBox';

export class HelloRetailWidget extends PureComponent {

    render() {
        const { id, title } = this.props;
        return <HelloRetailRecommendationBox title={title} ids={[id]}/>;
    }
}

export default HelloRetailWidget; 