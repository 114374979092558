/* eslint-disable */
import { MenuOverlayContainer as HoeksMenuOverlayContainer, mapStateToProps, mapDispatchToProps } from 'HoeksComponent/MenuOverlay/MenuOverlay.container';
import { connect } from 'react-redux';

/** @namespace Component/MenuOverlay/Container */
export class MenuOverlayContainer extends HoeksMenuOverlayContainer {
    handleSubcategoryClick(e, activeSubcategory) {
        const { changeHeaderState, closeOverlayMenu } = this.props;
        const { activeMenuItemsStack } = this.state;
        const { item_id, title } = activeSubcategory;

        e.stopPropagation();

        if (activeMenuItemsStack.includes(item_id)) {
            return;
        }

        this.setState(state => ({ activeMenuItemsStack: [item_id, ...state.activeMenuItemsStack] }));
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuOverlayContainer);