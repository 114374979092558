/* eslint-disable */

// imports the original component from vendor folder
import HoeksNavigationTabs from 'HoeksComponent/NavigationTabs/NavigationTabs.component';

import Link from 'Component/Link';

// import new extended styles file
import './NavigationTabs.override.style.scss';
export class NavigationTabs extends HoeksNavigationTabs { 
    renderCustomWishlistButton() {
        return (
            <div
            block="Header"
            elem="Button"
            mods={ {type: 'wishlist' } }
            >
                <Link
                to="/wishlist"
                mix={{ block: 'Header', elem: 'WishlistButtonWrapper' }}
                >
                    <span
                    aria-label="Wishlist"
                    block="Header"
                    elem="WishlistIcon"
                    />
                </Link>
            </div>
        );
    }

    renderCustomSearchButton() {
        return (
            <div
                block="Header"
                elem="Button"
                mods={ {type: 'search'} }
            >
                <input id="Search-Input" block="HelloRetailSearch" elem="Input" />
                <span block="HelloRetailSearch" elem="Input-Icon" ></span>
            </div>
        );
    }

    render() {
        const { device } = this.props;
        if (!device.isMobile) {
            return this.renderMenuOverlay();
        }

        return (
            <nav className="NavigationTabs-Nav">
                { this.renderNavigationState() }
                { this.renderMenuOverlay() }
                { this.renderCustomWishlistButton() }
                { this.renderCustomSearchButton() }
            </nav>
        )
    }
}

export default NavigationTabs;
