/* eslint-disable */

import { Notification as HoeksNotification } from 'HoeksComponent/Notification/Notification.component';

import './Notification.override.style.scss';

export class Notification extends HoeksNotification {

}

export default Notification;
