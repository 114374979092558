/* eslint-disable */
import VariantSelectedFilter from './VariantSelectedFilter.component';

/** @namespace Component/VariantSelectedFilter/Container */
export class VariantSelectedFilterContainer extends PureComponent {
    containerFunctions = {
        onClick: this.onClick.bind(this)
    }

    onClick(e) {
        e.nativeEvent.stopImmediatePropagation();

        const {
            filter: {
                code,
                val
            },
            updateFilter
        } = this.props;

        updateFilter(code, val);
    }

    render() {
        return (
            <VariantSelectedFilter
                { ...this.props }
                { ...this.containerFunctions }
            />
        );
    }
}

export default VariantSelectedFilterContainer;