/* eslint-disable */
const _getProductBreadcrumbs = (args, callback, instance) => {
    const [ product ] = args;
    const { categories, url, name } = product;

    if (!categories || !categories.length) {
        return [
            { url, name }
        ];
    }

    return callback.apply(instance, args);
}

export default {
    'Store/Breadcrumbs/Dispatcher': {
        'member-function': {
            _getProductBreadcrumbs
        }
    }
}