/* eslint-disable */
import { FieldSelect as SourceFieldSelect } from 'SourceComponent/FieldSelect/FieldSelect.component';
import './FieldSelect.override.style'

export class FieldSelect extends SourceFieldSelect {
    renderNativeOption = (option) => {
        const {
            id,
            value,
            disabled,
            label
        } = option;

        return (
            <option
                block="FieldSelect"
                elem="NativeBadge"
                key={id}
                id={id}
                value={value}
                disabled={disabled}
            >
                {label}
            </option>
        );
    };

    renderOption = (option) => {
        const {
            id,
            label,
            disabled
        } = option;

        const {
            isSelectExpanded: isExpanded,
            handleSelectListOptionClick,
            getOptionBadge
        } = this.props;
        const priceLabel = getOptionBadge(label)

        const onClick = disabled ? () => {} : () => handleSelectListOptionClick(option);

        return (
            <li
                block="FieldSelect"
                elem="Option"
                mods={{ isExpanded, isDisabled: disabled }}
                disabled={ disabled }
                key={id}
                /**
                 * Added 'o' as querySelector does not work with
                 * ids, that consist of numbers only
                 */
                id={`o${id}`}
                role="menuitem"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ onClick }
                // eslint-disable-next-line react/jsx-no-bind
                onKeyPress={() => handleSelectListOptionClick(option)}
                tabIndex={isExpanded ? '0' : '-1'}
            >
                {label}
                {priceLabel != '' && priceLabel != undefined ?
                    <span block="FieldSelect" elem={priceLabel != "News" ? "PriceLabel" : "PriceLabel News-badge"}>
                        {priceLabel}
                    </span> :
                    <></>}

            </li>
        );
    };
}

export default FieldSelect;