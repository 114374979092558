/* eslint-disable */

// imports the original component from vendor folder
import SourcePostsListing from 'scandipwa-blog/src/route/PostsListing/PostsListing.component';

// import dependencies
import ContentWrapper from 'Component/ContentWrapper';
import BlogCategories from 'scandipwa-blog/src/component/BlogCategories';
import BlogRecentPosts from 'scandipwa-blog/src/component/BlogRecentPosts';
import BlogSearchBar from 'scandipwa-blog/src/component/BlogSearchBar';
import Fade from 'react-reveal/Fade';

// import new extended styles file
import './PostsListing.override.style.scss';

export class PostsListing extends SourcePostsListing {
    updateMeta() {
        const { updateMeta } = this.props;

        updateMeta({
            title: __('Blog Overview'),
            canonical_url: this.getCanonicalUrl()
        });
    }

    render() {
        return (
            <main block="PostsListing">
                <div
                    block="PostsListing"
                    elem="Header"
                    style={ {backgroundImage: 'url(https://ingvardchristensen.hoeks.dk/pub/media/wysiwyg/banners/blog-background-1.jpg)'}}
                >
                    <h1>{ __('Articles') }</h1>
                </div>
                <ContentWrapper label="PostsListing">
                    <Fade bottom small duration={500}>
                        <div block="PostsListing" elem="Sidebar">
                            {/* <BlogSearchBar mix={ { block: 'PostsListing', elem: 'Search' } } /> */}
                            {/* <BlogRecentPosts /> */}
                            <BlogCategories />
                        </div>
                    </Fade>
                    <div block="PostsListing" elem="ColumnWrapper">
                        <div block="PostsListing" elem="Grid">
                            { this.renderGrid() }
                        </div>
                        <div>
                            { this.renderLoadMore() }
                        </div>
                    </div>
                </ContentWrapper>
            </main>
        );
    }

}

export default PostsListing;
