/* eslint-disable */
import Field from 'Component/Field';
import { CHECKBOX_TYPE } from 'Component/Field/Field.config';
import { PureComponent } from "react";
import { deleteGuestQuoteId, getGuestQuoteId } from 'Util/Cart';
import CheckoutQuery from 'Query/Checkout.query';
import NewsletterSubscriptionQuery from 'Query/NewsletterSubscription.query';
import { debounce, fetchMutation, fetchQuery } from 'Util/Request';
import { isSignedIn } from 'Util/Auth';
import {
    BILLING_STEP,
    DETAILS_STEP
} from 'Route/Checkout/Checkout.config';
import Link from 'Component/Link';

export class NewsLetterCheckoutPlugin extends PureComponent {
    onNewsLetterChange(x, value) {
        this.setState({ newsLetterChecked: value });
    }
}

const { onNewsLetterChange } = new NewsLetterCheckoutPlugin();

const addToContainerFunctions = (member, instance) => {
    return (
        {
            ...member,
            onNewsLetterChange: onNewsLetterChange.bind(instance)
        }
    )
}

// const saveGuestEmail = (args, callback, instance) => {
//     const { email, newsLetterChecked } = instance.state;
//     const { updateEmail } = instance.props;
//     const guestCartId = getGuestQuoteId();
//     const mutation = CheckoutQuery.getSaveGuestEmailMutation(email, guestCartId);

//     updateEmail(email);
//     return fetchMutation(mutation).then(
//         ({ setGuestEmailOnCart: data }) => {
//             if (data) {
//                 instance.setState({ isGuestEmailSaved: true });

//                 if (newsLetterChecked) {
//                     const subscribtionResult = subscribeToNewsletter(email);
//                     // console.log(subscribtionResult);
//                 }
//             }

//             return data;
//         },
//         instance._handleError
//     );
// }

const onShippingSuccess = (args, callback, instance) => {
    const { firstname, lastname } = args[0];
    const { email, newsLetterChecked } = instance.props;
    const name = firstname + ' ' + lastname;
    if (newsLetterChecked) {
        subscribeToNewsletter(email, name, '6');
    }
    return callback(...args);
}

const subscribeToNewsletter = (email, name, moebelhus) => {
    const mutation = NewsletterSubscriptionQuery.getSubscribeToNewsletterMutation(email, name, moebelhus);
    return fetchMutation(mutation).then(result => {
        return result;
    })
}

const renderGuestForm = (args, callback, instance) => {
    const { onNewsLetterChange, checkoutStep } = instance.props;
    const isBilling = checkoutStep === BILLING_STEP || checkoutStep === DETAILS_STEP;
    return (
        <>
            {callback(...args)}
            {!isBilling && !isSignedIn() ? (
                <>
                <Field
                    block="NewsLetter" elem="ConsentButton"
                    id="NewsLetter_Toggle"
                    name="NewsLetter_Toggle"
                    type={CHECKBOX_TYPE}
                    onChange={onNewsLetterChange}
                    label={__("Subscribe to newsletter")}
                />
                <p block="NewsLetter" elem="ConsentText">
                    {__(`Jeg giver hermed mit samtykke til, at Ingvard Christensen A/S sender mig markedsføringsmateriale inden for møbler, belysning og accessories, via e-mail og sociale medier såsom Facebook og Instagram etc.
                    med relevante nyheder, tilbud, information om nye produkter og services, information om efterladt kurv i vores webshop og invitationer til arrangementer mv.
                    Jeg kan til enhver tid tilbagekalde mit samtykke.`)}
                </p>
                <p block="NewsLetter" elem="PersonalInformationPolicy">
                    {__('Jeg kan læse mere om behandlingen af mine personoplysninger i Ingvard Christensens')}
                    <Link to="/persondatapolitik/">{__(' Politik for personoplysninger')}</Link>
                </p>
                </>
            ) : (<div></div>)}
        </>
    );
}

const renderShippingStep = (args, callback, instance) => {
    const { email } = instance.props
    const newsletterChecked = instance.props.newsLetterChecked != undefined ? instance.props.newsLetterChecked : false
    const result = callback(...args);
    return <result.type {...result.props} newsLetterChecked={newsletterChecked} email={email} />;
}

const saveAddressInformation = (args, callback, instance) => {
    return (callback(...args));
}

export default {
    'Route/Checkout/Container': {
        'member-property': {
            'containerFunctions': addToContainerFunctions
        },
        'member-function': {
            // 'saveGuestEmail': saveGuestEmail,
            'saveAddressInformation': saveAddressInformation
        }
    },
    'Route/Checkout/Component': {
        'member-function': {
            'renderGuestForm': renderGuestForm,
            'renderShippingStep': renderShippingStep
        }
    },
    'Component/CheckoutShipping/Container': {
        'member-function': {
            'onShippingSuccess': {
                implementation: onShippingSuccess,
                position: 1
            }
        }
    }
}