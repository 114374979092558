/* eslint-disable */
import { ProductCard } from 'Component/ProductCard/ProductCard.component';
import AddToCart from 'Component/AddToCart';

import './VariantProductCard.style';

/** @namespace Component/VariantProductCard/Component */
export class VariantProductCard extends ProductCard {
    renderVariantBadge() {
        return null;
    }

    renderCardLinkWrapperNew(children, mix = {}) {
        const { onVariantSelect } = this.props;

        return (
            <div
                block="ProductCard"
                elem="Link"
                onClick={ onVariantSelect }
            >
              { children }
            </div>
        );
    }

    renderAttribute({ code, label, value }) {
        return (
            <div
                block="VariantProductCard"
                elem="Attribute"
                key={ code }
            >
                <span
                    block="VariantProductCard"
                    elem="AttributeLabel"
                >
                    { label }:
                </span>
                <span
                    block="VariantProductCard"
                    elem="AttributeValue"
                >
                    { value }
                </span>
            </div>
        );
    }

    renderConfigurableAttributes() {
        const { configurableAttributes } = this.props;

        return configurableAttributes.map(this.renderAttribute);
    }

    renderMainDetails() {
        return this.renderConfigurableAttributes();
    }

    renderAddToCart() {
        const {
            product,
            product: {
                type_id
            }
        } = this.props;
        const configurableVariantIndex = -1;
        const quantity = 1;
        const groupedProductQuantity = {};
        const productOptionsData = {};

        if (type_id !== 'simple') {
            return this.renderCardLinkWrapperNew(
                <button block="Button">{ __('Add To Cart') }</button>
            );
        }

        return (
            <AddToCart
                product={ product }
                configurableVariantIndex={ configurableVariantIndex }
                mix={ { block: 'ProductActions', elem: 'AddToCart' } }
                quantity={ quantity }
                groupedProductQuantity={ groupedProductQuantity }
                productOptionsData={ productOptionsData }
            />
        );
    }

    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapperNew((
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                        { this.renderReviews() }
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderAdditionalProductDetails() }
                        { this.renderMainDetails() }
                        { this.renderProductPrice() }
                        { this.renderVisualConfigurableOptions() }
                    </div>
                </>
            ))
        );
    }

    renderCardListContent() {
        const { children, layout, renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <div block="ProductCard" elem="Link">
                { this.renderCardLinkWrapperNew(
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                ) }
                <div block="ProductCard" elem="Content" mods={ { layout } }>
                    <div block="ProductCard" elem="MainInfo">
                        { this.renderAdditionalProductDetails() }
                        { this.renderMainDetails() }
                        { this.renderReviews() }
                    </div>
                    <div block="ProductCard" elem="AttributeWrapper">
                        { this.renderProductPrice() }
                        { this.renderVisualConfigurableOptions() }
                    </div>
                    <div block="ProductCard" elem="ActionWrapper">
                        { this.renderAddToCart() }
                        { this.renderProductActions() }
                    </div>
                    <div block="ProductCard" elem="AdditionalContent">
                        { children }
                    </div>
                </div>
            </div>
        );
    }
}

export default VariantProductCard;