/* eslint-disable */
import ProductList from 'Component/ProductList';

/** @namespace Route/LocalWishlistPage/Component */
export class LocalWishlistPage extends PureComponent {
    renderProductList() {
        const {
            wishlistProducts = [],
            isLoading
        } = this.props;

        if (!isLoading && wishlistProducts.length <= 0) {
            return this.renderEmptyList();
        }

        return (
            <ProductList
                pages={ { 1: wishlistProducts } }
                pageSize={ 40 }
                isLoading={ isLoading }
                totalItems={ wishlistProducts.length }
                requestProductList={ () => {} }
                requestProductListInfo={ () => {} }
            />
        )
    }

    renderEmptyList() {
        return (
            <div
                block="LocalWishlistPage"
                elem="Empty"
            >
                { __('Your wishlist appears to be empty.') }
            </div>
        )
    }

    render() {
        return (
            <div
                block="LocalWishlistPage"
                elem="Container"
            >
                { this.renderProductList() }
            </div>
        )
    }
}

export default LocalWishlistPage;