/* eslint-disable */
import { ProductConfigurableAttributeDropdownContainer as HoeksProductConfigurableAttributeDropdownContainer } from 'HoeksComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.container';
export * from 'HoeksComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.container';

/** @namespace Component/ProductConfigurableAttributeDropdown/Container */
export class ProductConfigurableAttributeDropdownContainer extends HoeksProductConfigurableAttributeDropdownContainer {
    _getSelectOptions = () => {
        const {
            option: {
                attribute_options,
                attribute_code
            },
            getIsConfigurableAttributeAvailable
        } = this.props;

        if (!attribute_options) {
            // eslint-disable-next-line no-console
            console.warn(`Please make sure "${ attribute_code }" is visible on Storefront.`);
            return [];
        }

        return Object.values(attribute_options)
            .reduce((acc, option) => {
                const { value } = option;

                const isAvailable = getIsConfigurableAttributeAvailable({
                    attribute_code,
                    attribute_value: value
                });

                if (!isAvailable) {
                    return [
                        ...acc,
                        {
                            ...option,
                            id: value,
                            disabled: true
                        }
                    ]
                }

                return [...acc, {
                    ...option,
                    id: value
                }];
            }, []);
    }
}

export default ProductConfigurableAttributeDropdownContainer;