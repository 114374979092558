/* eslint-disable */
import { ProductActionsContainer as SourceProductActionsContainer, mapDispatchToProps, mapStateToProps } from 'SourceComponent/ProductActions/ProductActions.container';
import { connect } from 'react-redux';
import { parseDate } from 'Util/Product';
import { productHasSpecialPrice } from 'Util/Product';

export class ProductActionsContainer extends SourceProductActionsContainer {
    containerFunctions = {
        showOnlyIfLoaded: this.showOnlyIfLoaded.bind(this),
        onProductValidationError: this.onProductValidationError.bind(this),
        getIsOptionInCurrentVariant: this.getIsOptionInCurrentVariant.bind(this),
        setQuantity: this.setQuantity.bind(this),
        setGroupedProductQuantity: this._setGroupedProductQuantity.bind(this),
        clearGroupedProductQuantity: this._clearGroupedProductQuantity.bind(this),
        getIsConfigurableAttributeAvailable: this.getIsConfigurableAttributeAvailable.bind(this),
        checkSpecialPrice: this.checkSpecialPrice.bind(this)
    };

    themeContainerProps = () => ({
        ...this.containerProps(),
        specialPricePeriod: this.getSpecialPricePeriod()
    });

    getSpecialPricePeriod() {
        const {
            productOrVariant
        } = this.props;

        const hasSpecialPrice = productHasSpecialPrice(productOrVariant);

        const from = this.getSpecialPriceDate('special_from_date');
        const to = this.getSpecialPriceDate('special_to_date');

        return {
            hasSpecialPricePeriod: hasSpecialPrice && from && to,
            specialPriceFrom: from,
            specialPriceTo: to
        };
    }

    getSpecialPriceDate(prop) {
        const {
            productOrVariant: {
                [prop]: dateProp = ''
            } = {}
        } = this.props;

        const date =  parseDate(dateProp);

        if (!date) {
            return null
        }

        return this.formatDate(date);
    }

    formatDate(date) {
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }

    checkSpecialPrice() {
        const { productOrVariant, productOrVariant: { special_from_date, special_to_date } } = this.props;
        if (productOrVariant.price_range != undefined) {
            if (productOrVariant.price_range.maximum_price != undefined) {
                const {
                    price_range: {
                        maximum_price: {
                            discount: {
                                amount_off, percent_off
                            }
                        }
                    }
                } = productOrVariant

                const today = new Date();

                if (amount_off || percent_off) {
                    if (this.parseDate(special_from_date) <= today && this.parseDate(special_to_date) >= today) {
                        return true;
                    }

                    if (this.parseDate(special_from_date) <= today && special_to_date == null) {
                        return true;
                    }

                    if (special_from_date == null && this.parseDate(special_to_date) >= today) {
                        return true;
                    }

                    if (special_from_date == null && special_to_date == null) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    parseDate(date) {
        if (date == null) return null
        const dateArr = date.split(/[^0-9]/);
        const parsedDate = new Date(dateArr[0], dateArr[1] - 1, dateArr[2], dateArr[3], dateArr[4], dateArr[5])
        return parsedDate;
    }

    render() {
        const result = super.render();

        if (!result || !React.isValidElement(result)) {
            return result;
        }

        return React.cloneElement(result, { ...this.themeContainerProps() });
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductActionsContainer);