// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Html from 'Component/Html';
import Field from 'Component/Field';
import { TEXT_TYPE, CHECKBOX_TYPE } from 'Component/Field/Field.config';
import './NewsletterForm.style';

/** @namespace Hoeks/NewsletterPage/Component/NewsletterForm/Component/NewsletterFormComponent */
export class NewsletterFormComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        const { onCheckboxChange, onNameFieldChange, onEmailFieldChange, onBtnClick } = this.props;

        return (
            <div block="NewsletterForm">
                <div id="newsletter_warehouses" class="ic-newsletter__warehouses">
                    <label class="ic-newsletter__warehouse">
                        <div class="icheckbox">
                            <Field className="checkbox" type={CHECKBOX_TYPE} id="Esbjerg" name="warehouses[]" onChange={onCheckboxChange} value="1" />
                            <ins class="iCheck-helper"></ins>
                        </div>
                        {__('Esbjerg')}
                    </label>
                    <label class="ic-newsletter__warehouse">
                        <div class="icheckbox">
                            <Field className="checkbox" type={CHECKBOX_TYPE} id="Vejle" name="warehouses[]" onChange={onCheckboxChange} value="3" />
                            <ins class="iCheck-helper"></ins>
                        </div>
                        {__('Vejle')}
                    </label>
                    <label class="ic-newsletter__warehouse">
                        <div class="icheckbox">
                            <Field className="checkbox" type={CHECKBOX_TYPE} id="Odense" name="warehouses[]" onChange={onCheckboxChange} value="4" />
                            <ins class="iCheck-helper"></ins>
                        </div>
                        {__('Odense')}
                    </label>
                    <label class="ic-newsletter__warehouse">
                        <div class="icheckbox">
                            <Field className="checkbox" type={CHECKBOX_TYPE} id="Lyngby" name="warehouses[]" onChange={onCheckboxChange} value="5" />
                            <ins class="iCheck-helper"></ins>
                        </div>
                        {__('Lyngby')}
                    </label>
                    <label class="ic-newsletter__warehouse">
                        <div class="icheckbox">
                            <Field className="checkbox" type={CHECKBOX_TYPE} id="Aarhus" name="warehouses[]" onChange={onCheckboxChange}  value="2" />
                            <ins class="iCheck-helper"></ins>
                        </div>
                        {__('Aarhus')}
                    </label>
                    <label class="ic-newsletter__warehouse">
                        <div class="icheckbox">
                            <Field className="checkbox" type={CHECKBOX_TYPE} id="Herning" name="warehouses[]" onChange={onCheckboxChange}  value="7" />
                            <ins class="iCheck-helper"></ins>
                        </div>
                        {__('Herning')}
                    </label>
                </div>
                <div class="ic-newsletter-form__inputs">
                    <div class="form-group">
                        <Field className="form-control" type={TEXT_TYPE} id="name" name="name" placeholder="Navn" onChange={onNameFieldChange} autocomplete="name" />
                    </div>
                    <div class="form-group">
                        <Field class="form-control" type={TEXT_TYPE} id="email" name="email" placeholder="Email" onChange={onEmailFieldChange} autocomplete="email" />
                    </div>
                    <div class="ic-newsletter-form__buttons">
                        <input id="submit-btn" class="form-btn form-btn-primary" type="button" onClick={onBtnClick} value="Tilmeld dig" />
                    </div>
                </div>
            </div>
        );
    }
}

export default NewsletterFormComponent;
