/* eslint-disable */
import currencyMap from 'Util/Price/Price.config';

const currencyPositionMap = {
    'USD': 0,
    'EUR': 1,
    'DKK': 1
}

const formatPrice = (args, callback, context) => {
    const [price, currency = 'USD'] = args;

    // This is dirty, but it works. de-DE shows DKK after price.
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency }).format(price);

    const correctedPrice = decimalCount(price) == 1 || decimalCount(price) >=2 && typeof(price) == "number"
        ? price.toFixed(2)
        : price;

    if (currencyPositionMap[currency]) {
        return `${correctedPrice} ${currencyMap[currency]}`;
    } else {
        return `${currencyMap[currency]}${correctedPrice}`;
    }
}

const decimalCount = (number) => {
    const numberStr = String(number);
    if (numberStr.includes('.')) {
        return numberStr.split('.')[1].length;
    }
    return 0;
}

export default {
    'Util/Price/formatPrice': {
        'function': formatPrice
    }
}