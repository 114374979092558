/* eslint-disable */
import { ProductCardContainer, mapStateToProps, mapDispatchToProps } from 'Component/ProductCard/ProductCard.container';
import { connect } from 'react-redux';
import { Subscribe } from 'unstated';
import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import VariantProductCard from './VariantProductCard.component';

/** @namespace Component/VariantProductCard/Container */
export class VariantProductCardContainer extends ProductCardContainer {
    containerFunctions = {
        ...this.containerFunctions,
        onVariantSelect: this.onVariantSelect.bind(this)
    }

    updatedContainerProps = () => ({
        configurableAttributes: this.getConfigurableAttributes()
    })

    getConfigurableAttributes() {
        const { configurable_options } = this.props;

        const {
            product: {
                attributes
            } = {}
        } = this.props;

        return Object.keys(configurable_options).map(key => {
            const {
                [key]: {
                    attribute_label,
                    attribute_value,
                    attribute_options: {
                        [attribute_value]: {
                            label
                        } = {}
                    } = {}
                } = {}
            } = attributes;

            if (attribute_label && label) {
                return { label: attribute_label, value: label, id: attribute_value, code: key };
            }

            return null;
        }).filter(value => value !== null);
    }

    onVariantSelect() {
        const { onVariantSelect, updateConfigurableVariantFull } = this.props;
        const parameters = Object.fromEntries(this.getConfigurableAttributes().map(({ code, id }) => [code, id]));

        onVariantSelect();
        updateConfigurableVariantFull(parameters);
    }

    render() {
        return (
            <Subscribe to={ [SharedTransitionContainer] }>
                { ({ registerSharedElement }) => (
                    <VariantProductCard
                        { ...{ ...this.props, registerSharedElement } }
                        { ...this.containerFunctions }
                        { ...this.containerProps() }
                        { ...this.updatedContainerProps() }
                    />
                ) }
            </Subscribe>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VariantProductCardContainer);