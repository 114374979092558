/* eslint-disable */
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

const hrSetEmail = (args, callback, instance) => {
    const { email } = instance.state;
    BrowserDatabase.setItem(email, 'HRCONVERSIONEMAIL', ONE_MONTH_IN_SECONDS);
    return callback(...args);
}

export default {
    'Route/Checkout/Container': {
        'member-function': {
            saveGuestEmail: hrSetEmail
        }
    }
}