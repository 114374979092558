/* eslint-disable */
import Field from 'Component/Field';
import './VariantCustomFilterOption.style';

/** @namespace Component/VariantCustomFilterOption/Component */
export class VariantCustomFilterOption extends PureComponent {
    render() {
        const {
            customFilterKey,
            onClick,
            isChecked,
            label
        } = this.props;

        return (
            <Field
                id={ customFilterKey }
                name={ customFilterKey }
                type='checkbox'
                label={ label }
                mix={ { block: 'VariantCustomFilterOption', elem: 'Text' } }
                checked={ isChecked }
                onChange={ onClick }
            />
        );
    }
}

export default VariantCustomFilterOption;