
/* eslint-disable */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { showPopup } from 'Store/Popup/Popup.action';

import SparxpresTab from './SparxpresTab.component';

/** @namespace Hoeks/Sparxpres/Component/SparxpresTab/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
});

/** @namespace Hoeks/Sparxpres/Component/SparxpresTab/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup('FinancingPopup', payload))
});

/** @namespace Hoeks/Sparxpres/Component/SparxpresTab/Container/SparxpresTabContainer */
export class SparxpresTabContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    // renderContent() {

    // }

    showPopup() {
        const { showPopup } = this.props;
        const title = '';
        const text = '';

        return showPopup({
            title, text
        });
    }

    render() {
        return (
            <SparxpresTab
                {...this.props}
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default SparxpresTabContainer;
