/* eslint-disable */

// imports the original component from vendor folder
import SourceLocalWishlistPage from '@hoeks/local-wishlist/src/route/LocalWishlistPage/LocalWishlistPage.component';

// import new extended styles file
import './LocalWishlistPage.override.style.scss';

export class LocalWishlistPage extends SourceLocalWishlistPage { 

}

export default LocalWishlistPage; 
