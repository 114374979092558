// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './snapengage.style';

/** @namespace Hoeks/Snapengage/Component/Snapengage/Component/SnapengageComponent */
export class SnapengageComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        const { contactButtonClick, contactPopoutCloseClick, startChatButtonClick, chatAgentOnline } = this.props;
        return (
            <div block="snapengage">
                <button class="contact-button" onClick={contactButtonClick}>
                    <span class="icon-chat"></span>
                </button>
                <div className="contact-popout-curtain" onClick={contactPopoutCloseClick}></div>
                <div className="contact-popout">
                    <div className="content">
                        <a href="tel:004575470111" class="phone-number text-uppercase">
                            <span class="icon-phone"></span>
                            Kontakt os<br /> +45 7547 0111
                        </a>
                        <a href="mailto:kontakt@ingvardchristensen.dk" class="text-uppercase">
                            <div class="icon-letter"></div>
                            E-mails vil blive besvaret<br /> på hverdage indenfor få timer
                        </a>
                        <button type="button" className="start-chat-button snapengage text-uppercase" onClick={startChatButtonClick}>
                            <span class="icon-chat"></span>
                            {
                                chatAgentOnline ?
                                    <span class="online">Start chat nu</span> 
                                    :
                                    <span class="offline">Chat er ikke tilgængelig<br /> på nuværende tidspunkt</span>
                            }
                        </button>
                    </div>
                    <div class="header text-uppercase">
                        <span class="icon-chat"></span>
                        Hvad kan vi hjælpe med?
                        <button class="close" onClick={contactPopoutCloseClick}><span class="icon-close"></span></button>
                    </div>
                </div>
            </div>
        );
    }
}

export default SnapengageComponent;
