/* eslint-disable */

import { Header as HoeksHeader } from 'HoeksComponent/Header/Header.component';

import NavigationTabs from 'HoeksComponent/NavigationTabs';
import CmsBlock from 'Component/CmsBlock';
import Logo from 'Component/Logo';
import media from 'Util/Media';
import CSS from 'Util/CSS';
import {
    CART_OVERLAY,
    CUSTOMER_WISHLIST
} from 'Component/Header/Header.config';
import { LOGO_MEDIA } from 'Util/Media/Media';
import OfflineNotice from 'Component/OfflineNotice';
import Link from 'Component/Link';
import HelloRetailSearch from '@hoeks/helloretail/src/component/HelloRetailSearch'

import './Header.override.style.scss';

export class Header extends HoeksHeader {

    state = { scrolled: false } 

    renderLogoImage() {
        const {
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width
        } = this.props;

        // if no src defined from the backend, pass null in order to display placeholder
        // and prevent unnecessary load of corrupted resource
        const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

        CSS.setVariable(this.logoRef, 'header-logo-height', `${logo_height}px`);
        CSS.setVariable(this.logoRef, 'header-logo-width', `${logo_width}px`);

        return (
            <Logo
              src={ `/media/logo/stores/1/ic.svg` }
              alt={ logo_alt }
              title={ logo_alt }
            />
        );
    }

    renderContacts() {
        return (
            <></>
        );
    }

    renderCustomTopMenu() {
        const { device } = this.props;
        if (device.isMobile) {
            return null;
        }
        return (
            <>
                <div block="Header" elem="TopMenu">
                    <CmsBlock identifier={ "header-top-section" } />
                </div>
            </>
        );
    }

    renderTopMenu() {
        return (
            <>
                { this.renderCustomTopMenu() }
                <NavigationTabs />
            </>
        )
    }

    componentDidMount(){
        window.addEventListener('scroll', () => {
           const scrolled = (window.scrollY !== 0);
           this.setState({ scrolled });
        });
    }

    renderBackButton() {
        return null;
    }

    renderCustomWishlistButton() {
        return (
            <div
            block="Header"
            elem="Button"
            mods={ {type: 'wishlist' } }
            >
                <Link
                to="/wishlist"
                mix={{ block: 'Header', elem: 'WishlistButtonWrapper' }}
                >
                    <span
                    aria-label="Wishlist"
                    block="Header"
                    elem="WishlistIcon"
                    />
                </Link>
            </div>
        );
    }

    renderSearchField(isVisible = false) {
        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            navigationState: { name },
            isCheckout,
            hideActiveOverlay
        } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <HelloRetailSearch />
        );
    }

    render() {
        const { stateMap } = this;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            device
        } = this.props;

        const {
            scrolled
        } = this.state

        if (!device.isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        return (
            <section block="Header" elem="Wrapper" mods={ { name, scrolled } }>
                <header
                  block="Header"
                  mods={ { name, isHiddenOnMobile, isCheckout } }
                  mix={ { block: 'FixedElement', elem: 'Top' } }
                  ref={ this.logoRef }
                >
                    { this.renderTopMenu() }
                    <nav block="Header" elem="Nav">
                        { this.renderNavigationState() }
                        { this.renderCustomWishlistButton() }
                    </nav>
                    { this.renderMenu() }
                </header>
                <OfflineNotice />
            </section>
        );
    }


}

export default Header;
