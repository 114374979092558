/* eslint-disable */

import SparxpresTab from "../component/SparxpresTab"

const PRODUCT_FINANCING = 'financing';

const renderSparxpresTab = (key) => {
    return <SparxpresTab key={key} isProductPage={true} />
}

const addToProductTab = (member, instance) => {
    return {
        ...member,
        [PRODUCT_FINANCING]: {
            name: __('Financing'),
            // Return true since we always show 'Add review' button
            shouldTabRender: () => true,
            render: (key) => renderSparxpresTab(key)
        }
    }
}

// const addToCart = (args, callback, instance) => {
//     return (
//         <>
//             {callback(...args)}
//             <SparxpresTab isProductPage={false} />
//         </>
//     )
// }

export default {
    'Route/ProductPage/Component': {
        'member-property': {
            tabMap: addToProductTab
        }
    }
}