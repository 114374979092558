/* eslint-disable */
const awTrack = (args, callback, instance) => {
    const [cartData] = args;
    const { subtotal_incl_tax: subtotal, items } = cartData;
    if (!items) return;
    const productIds = items.map(item => item.product.sku);
    _awev = (window._awev || []); _awev.push(["bind_once", "context_ready", function () {
        ADDWISH_PARTNER_NS.api.cart.setCart({
            total: subtotal,
            productNumbers: productIds
          }, 
          function() {
          });
    }]);
      
    return callback(...args);
}

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            _updateCartData: awTrack
        }
    }
}