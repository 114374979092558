/* eslint-disable */
import { ProductConfigurableAttributeDropdown as SourceProductConfigurableAttributeDropdown } from 'SourceComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.component';
import Field from 'Component/Field';

// import new extended styles file
import './ProductConfigurableAttributeDropdown.override.style.scss';

export class ProductConfigurableAttributeDropdown extends SourceProductConfigurableAttributeDropdown {
    render() {
        const {
            selectOptions,
            selectValue,
            selectName,
            selectLabel,
            onChange,
            variants,
            option,
            parameters,
            hasSpecialPrice,
            special_from_date,
            special_to_date,
            attributes,
            variantAttributes,
            product
        } = this.props;

        return (
            <Field
                id={selectName}
                name={selectName}
                type="select"
                placeholder={__('Choose %s', selectLabel.toLowerCase())}
                mix={{ block: 'ProductConfigurableAttributeDropdown' }}
                selectOptions={selectOptions}
                value={selectValue}
                onChange={onChange}
                isConfigurableAttribute={true}
                product={product}
                variants={variants}
                attributeCode={option.attribute_code}
                parameters={parameters}
                attributes={attributes}
                variantAttributes={variantAttributes}
                hasSpecialPrice={hasSpecialPrice}
                special_from_date={special_from_date}
                special_to_date={special_to_date}
            />
        );
    }
}

export default ProductConfigurableAttributeDropdown;