/* eslint-disable */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ExpandableContent from 'Component/ExpandableContent';
import Popup from 'Component/Popup';

import './SparxpresTab.style';

/** @namespace Hoeks/Sparxpres/Component/SparxpresTab/Component/SparxpresTabComponent */
export class SparxpresTabComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    componentDidMount() {
        window.dispatchEvent(new Event("sparxpresRender"));
    }

    renderContent() {

    }

    renderOnProductPage() {
        // console.log("test");
        return (
            <ExpandableContent heading={__('Financing')}>
                <div id="SPX_Product">
                </div>
            </ExpandableContent>
        )
    }

    renderOnCart() {

        return (
            <div id="SPX_Cart">
            </div>
        )
    }

    render() {
        const { isProductPage } = this.props;
        var content;
        if (isProductPage) {
            content = this.renderOnProductPage();
        } else {
            content = this.renderOnCart();
        }
        return (
            <div block="SparxpresTab">
                { /* TODO: Implement render method */}
                {/* <h4>Indsæt sparxpres her</h4> */}
                {content}
                {/* {this.renderContent()} */}
            </div>
        );
    }
}

export default SparxpresTabComponent;
