/* eslint-disable */

// imports the original component from vendor folder
import SourceCategoryBlock from '@hoeks/categoryblock/src/component/CategoryBlock/CategoryBlock.component';
export * from '@hoeks/categoryblock/src/component/CategoryBlock/CategoryBlock.component';

// import new extended styles file
import './CategoryBlock.override.style.scss';

export default SourceCategoryBlock; 
