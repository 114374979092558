/* eslint-disable */
import { ClerkContainer as SourceClerkContainer, mapStateToProps, mapDispatchToProps } from '@hoeks/clerk/src/component/Clerk/Clerk.container';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';

import Clerk from './Clerk.component';
import { CLERK_ENABLED, LINK_TYPE, CLERK_KEY } from '@hoeks/clerk/src/component/Clerk/Clerk.config';
import { getIndexedClerkProduct, prepareRequest, processLinked, processLinkedData } from '@hoeks/clerk/src/util/Clerk';
import { callApi } from '@hoeks/clerk/src/util/Clerk/Api';

/** @namespace Hoeks/Clerk/Component/Clerk/Container/ClerkContainer */
export class ClerkContainer extends SourceClerkContainer {
    getPayload(){
        const { title, labels, data = {} } = this.props;
        const { numberOfProductsToDisplay } = this.state;
        const payload = data.template ? 
        {
            attributes: [
                'id', 
                'name', 
                'price', 
                'image', 
                'url', 
                'sku', 
                'list_price', 
                'on_sale', 
                'saerligt_god_pris', 
                'special_from_date', 
                'special_to_date',
                'badge_limited_edition',
                'badge_new',
                'variant_badge',
                'product_type'
            ],
            labels: labels || [title],
            ...data
        }
            :  
        {
            limit: numberOfProductsToDisplay,
            attributes: [
                'id', 
                'name', 
                'price', 
                'image', 
                'url', 
                'sku', 
                'list_price', 
                'on_sale', 
                'saerligt_god_pris', 
                'special_from_date', 
                'special_to_date',
                'badge_limited_edition',
                'badge_new',
                'variant_badge',
                'product_type'
            ],
            labels: labels || [title],
            ...data
        };
        return payload;

    }

    handleResponse = (response) => {
        const { defaultCurrency } = this.props;
        if (response.status == 'error') return;
        const skus = response.product_data.map(item => item.sku);
        const indexedItems = response.product_data.map((item) => {
            const indexItem = getIndexedClerkProduct(item, defaultCurrency);
            indexItem.saerligt_god_pris = item.saerligt_god_pris;
            indexItem.special_from_date = item.special_from_date;
            indexItem.special_to_date = item.special_to_date;
            indexItem.badge_limited_edition = item.badge_limited_edition;
            indexItem.badge_new = item.badge_new;
            indexItem.variant_badge = item.variant_badge;
            indexItem.maximum_price = indexItem.minimum_price;
            indexItem.type_id = item.product_type;
            return indexItem;
        });
        this.setState({ linkedProducts: processLinked(indexedItems, skus, true), areDetailsLoaded: true });
        const increment = this.increment;

        return;
        executeGet(prepareQuery(prepareRequest(skus)), 'ClerkProducts', 86400)
            .then((result) => this.onSuccess(result, skus, indexedItems, increment))
            .catch(
                (e) => console.log('error', 'Error fetching NewProducts!', e)
            );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClerkContainer);
