/* eslint-disable */
import { ALLOWED_PRODUCT_TYPES } from './AddToWishlistButton.config';

import './AddToWishlistButton.style';

/** @namespace Component/AddToWishlistButton/Component */
export class AddToWishlistButton extends PureComponent {
    renderButton() {
        const {
            mix,
            isInWishlist,
            onButtonClick
        } = this.props;

        return (
            <button
                block="AddToWishlistButton"
                elem="Button"
                title="Add to wishlist"
                mix={ { block: 'Button', mix } }
                mods={ { isInWishlist } }
                onClick={ onButtonClick }
            >
                <div
                    block="AddToWishlistButton"
                    elem="Heart"
                />
            </button>
        )
    }

    renderContent() {
        return (
            <div block="AddToWishlistButton">
                { this.renderButton() }
            </div>
        )
    }

    render() {
        const {
            isWishlistEnabled,
            productOrVariant: {
                type_id
            }
        } = this.props;

        if (!isWishlistEnabled || !ALLOWED_PRODUCT_TYPES.includes(type_id)) {
            return null;
        }

        return this.renderContent();
    }
}

export default AddToWishlistButton;