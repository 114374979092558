/* eslint-disable */
import ProductConfigurableAttributesPopup from './ProductConfigurableAttributesPopup.component';
import { connect } from 'react-redux';
import { showPopup } from 'Store/Popup/Popup.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { PRODUCT_CONFIGURABLE_ATTRIBUTES_POPUP_ID } from './ProductConfigurableAttributesPopup.config';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';

/** @namespace Component/ProductConfigurableAttributesPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Component/ProductConfigurableAttributesPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload, id) => dispatch(showPopup(id, payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE))
});

/** @namespace Component/ProductConfigurableAttributesPopup/Container */
export class ProductConfigurableAttributesPopupContainer extends PureComponent {
    state = {
        filters: {},
        enabledCustomFilters: []
    }

    containerFunctions = {
        onPopupButtonClick: this.onPopupButtonClick.bind(this),
        onVariantSelect: this.onVariantSelect.bind(this),
        updateFilter: this.updateFilter.bind(this),
        getQtyPreview: this.getQtyPreview.bind(this)
    }

    containerProps = () => ({
        customFilters: this.getCustomFilters(),
        filteredVariants: this.getFilteredVariants(),
        keyPrefix: this.getKeyPrefix()
    })

    onPopupButtonClick() {
        const {
            showPopup,
            product: { name }
        } = this.props;

        showPopup({ title: __('Variants of: %s', name) }, PRODUCT_CONFIGURABLE_ATTRIBUTES_POPUP_ID);
    }

    onVariantSelect() {
        const { hideActiveOverlay, goToPreviousHeaderState } = this.props;

        hideActiveOverlay();
        goToPreviousHeaderState();
    }

    getCustomFilters() {
        return {};
    }

    getQtyPreview(code, value) {
        const previewState = this.updateFilter(code, value, true);
        const { filters: previewFilters } = previewState;
        const previewVariants = this.getFilteredVariants(previewFilters);

        return previewVariants.length;
    }

    getFilteredVariants(previewFilters = null) {
        const variants = this.getSortedVariants();
        const { filters, enabledCustomFilters } = this.state;
        const finalFilters = Object.entries(previewFilters ? previewFilters : filters).map(([key, values]) => ({ code: key, values }));

        return variants.filter(({ attributes, ...rest }) =>
            enabledCustomFilters.every(filterKey => this.getCustomFilters()[filterKey].filter({ ...rest, attributes }))
            && finalFilters.every(({ code, values = [] }) => !values.length || values.includes(attributes?.[code]?.attribute_value)));
    }

    getSortedVariants() {
        const { variants = [] } = this.props;

        return variants.slice().sort(this.getSort());
    }

    getSort() {
        return () => 0;
    }

    getKeyPrefix() {
        const { isMobile } = this.props;

        return isMobile ? 'MOBILE' : 'DESKTOP';
    }

    updateFilter(code, value, preview = false) {
        if (value === undefined && Object.keys(this.getCustomFilters()).includes(code)) {
            this.setState(state => ({
                ...state,
                enabledCustomFilters: state.enabledCustomFilters.includes(code)
                    ? [...state.enabledCustomFilters.filter(val => val !== code)]
                    : [...state.enabledCustomFilters, code]
            }));
            return;
        }

        const updateState = state => {
            const {
                filters,
                filters: {
                    [code]: currentFilter = []
                } = {}
            } = state;

            const finalFilter =
                currentFilter.includes(value)
                ? [ ...currentFilter.filter(val => val !== value) ]
                : [ ...currentFilter, value ]

            return {
                ...state,
                filters: {
                    ...filters,
                    [code]: finalFilter
                }
            }
        };

        if (preview) {
            return updateState(this.state);
        }

        this.setState(updateState);
    }

    render() {
        return (
            <ProductConfigurableAttributesPopup
                { ...this.props }
                { ...this.containerFunctions }
                { ...this.state }
                { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductConfigurableAttributesPopupContainer);