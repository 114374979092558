/* eslint-disable */
import { Field } from 'Util/Query';
import { NewsletterSubscriptionQuery as SourceNewsletterSubscriptionQuery } from 'HoeksQuery/NewsletterSubscription.query';

export class NewsletterSubscriptionQuery extends SourceNewsletterSubscriptionQuery {
    getSubscribeToNewsletterMutation(email, name, moebelhus) {
        return new Field('subscribeEmailToNewsletter')
            .addArgument('email', 'String!', email)
            .addArgument('name', 'String!', name)
            .addArgument('moebelhus', 'String!', moebelhus)
            .addFieldList(this._getPageFields());
    }
}

export default new NewsletterSubscriptionQuery();