/* eslint-disable */

// imports the original component from vendor folder
import HoeksExpandableContent from 'HoeksComponent/ExpandableContent/ExpandableContent.component';
import Loader from 'Component/Loader';

// import new extended styles file
import './ExpandableContent.override.style.scss';

export class ExpandableContent extends HoeksExpandableContent { 
    scrollToExpandedContent() {
        const {
            isContentExpanded = false
        } = this.state;

        if (!isContentExpanded) {
            return;
        }

        return super.scrollToExpandedContent();
    }

    renderContent() {
        const {
            isLoading
        } = this.props;

        const result = super.renderContent();

        if (!React.isValidElement(result)) {
            return result;
        }

        if (result.props.children) {
            const updatedChildren = React.Children.map(result.props.children, (child) => React.isValidElement(child) ? React.cloneElement(child, { isLoading }) : child);

            const {
                className = ''
            } = result.props;

            const updatedResult = React.cloneElement(
                result,
                { className: isLoading ? `${className} ExpandableContent-Content_isLoading` : className },
                [ ...updatedChildren, <Loader isLoading={ isLoading }/> ]
            );
 
            return updatedResult;
        }

        return result;
    }
}

export default ExpandableContent;
