/* eslint-disable */
import ExpandableContent from 'Component/ExpandableContent';
import Loader from 'Component/Loader';
import ClickOutside from 'Component/ClickOutside';
import VariantFilterOption from '../VariantFilterOption';
import VariantCustomFilterOption from '../VariantCustomFilterOption';
import VariantSelectedFilter from '../VariantSelectedFilter';

import './VariantFilters.style';

/** @namespace Component/VariantFilters/Component */
export class VariantFilters extends PureComponent {
    renderFilterDropdown = this.renderFilterDropdown.bind(this);
    renderFilterOption = this.renderFilterOption.bind(this);
    renderSelectedFilter = this.renderSelectedFilter.bind(this);
    renderCustomFilterOption = this.renderCustomFilterOption.bind(this);

    renderFilterOption(attribute, option) {
        const { updateFilter, filters, getQtyPreview } = this.props;

        const { attribute_code } = attribute;

        const { value } = option;

        return (
            <VariantFilterOption
                key={ `${attribute_code}-${value}` }
                attribute={ attribute }
                option={ option }
                updateFilter={ updateFilter }
                filters={ filters }
                getQtyPreview={ getQtyPreview }
            />
        );
    }

    renderFilterDropdown(attribute) {
        const {
            getIsDropdownOpen,
            toggleDropdown,
            closeDropdown
        } = this.props;

        const {
            attribute_code,
            attribute_options,
            attribute_label
        } = attribute;

        // Added div wrapper to make ClickOutside work.
        return (
            <ClickOutside
                key={ attribute_code }
                onClick={ () => closeDropdown(attribute_code) }
            >
                <div
                    block="VariantFilters"
                    elem="DropdownWrapper"
                >
                    <ExpandableContent
                        heading={ attribute_label }
                        mix={ { block: 'VariantFilters', elem: 'Expandable' } }
                        isContentExpanded={ getIsDropdownOpen(attribute_code) }
                        onClick={ () => toggleDropdown(attribute_code) }
                    >
                        { Object.values(attribute_options).map((option) => this.renderFilterOption(attribute, option)) }
                    </ExpandableContent>
                </div>
            </ClickOutside>
        );
    }

    renderFilters() {
        const { configurable_options } = this.props;

        return Object.values(configurable_options).map(this.renderFilterDropdown);
    }

    renderSelectedFilter(filter) {
        const { updateFilter } = this.props;

        const { code, value } = filter;

        return (
            <VariantSelectedFilter
                key={ `${code}-${value}` }
                filter={ filter }
                updateFilter={ updateFilter }
            />
        );
    }

    renderCustomFilterOption(key, label) {
        const { enabledCustomFilters, updateFilter } = this.props;

        return (
            <VariantCustomFilterOption
                key={ key }
                enabledCustomFilters={ enabledCustomFilters }
                updateFilter={ updateFilter }
                customFilterKey={ key }
                label={ label }
            />
        );
    }

    renderCustomFilters() {
        const { customFilters } = this.props;

        return Object.entries(customFilters).map(([key, { label }]) => this.renderCustomFilterOption(key, label));
    }

    renderSelectedFilters() {
        const { activeFilters } = this.props;

        if (!activeFilters.length) {
            return null;
        }

        return (
            <div
                block="VariantFilters"
                elem="SelectedFilters"
            >
                <div
                    block="VariantFilters"
                    elem="Label"
                >
                    { __('Selected filters') }
                </div>
                { activeFilters.map(this.renderSelectedFilter) }
            </div>
        );
    }

    render() {
        const { isLoading } = this.props;

        return (
            <div
                block="VariantFilters"
                elem="Wrapper"
            >
                <Loader isLoading={ isLoading }/>
                <div
                    block="VariantFilters"
                    elem="FilteringWrapper"
                >
                    { this.renderFilters() }
                </div>
                <div
                    block="VariantFilters"
                    elem="SelectedWrapper"
                >
                    { this.renderSelectedFilters() }
                </div>
                <div
                    block="VariantFilters"
                    elem="CustomFilters"
                >
                    { this.renderCustomFilters() }
                </div>
            </div>
        );
    }
}

export default VariantFilters;