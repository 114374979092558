/* eslint-disable */
import HelloRetailWidget from '../component/HelloRetailWidget';

const HelloRetail = 'HelloRetail';
const addHelloRetailWidget = (member, instance) => {

    return {
        ...member,
        [HelloRetail]: {
            component: HelloRetailWidget
        }
    }
}

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': addHelloRetailWidget
        }
    },
}