import LocalWishlistReducer from '../store/LocalWishlist/LocalWishlist.reducer';

const getStaticReducers = (args, callback) => ({
    ...callback(args),
    LocalWishlistReducer
});

export default {
    'Store/Index/getReducers': {
        function: getStaticReducers
    }
};