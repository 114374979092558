/* eslint-disable */
import { RouterContainer as HoeksRouterContainer, mapStateToProps, mapDispatchToProps } from 'HoeksComponent/Router/Router.container';
export * from 'HoeksComponent/Router/Router.container';
import { connect } from 'react-redux'
import history from 'Util/History';

export class RouterContainer extends HoeksRouterContainer {
    componentDidMount(){
        super.componentDidMount();

        this.restoreScroll = this.restoreScroll.bind(this);

        history.listen((_, type) => {
            if (type === 'POP') {
                this.restoreScroll(Date.now(), 5000, 30);
            }
        });
    }

    restoreScroll(start, maxTime, interval) {
        setTimeout(() => {
            if (!window.scrollProductId) {
                return;
            }

            const elem = document.querySelector(`.ProductCard_id_${window.scrollProductId}`)

            if (!elem && Date.now() - start < maxTime) {
                this.restoreScroll(start, maxTime, interval);
                return;
            }

            if (elem && !this.isElementInViewport(elem)) {
                elem.scrollIntoView(false);
            }

            window.scrollProductId = null;
        }, interval);
    }

    isElementInViewport(elem) {
        const rect = elem.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);