/* eslint-disable */
import VariantFilters from './VariantFilters.component';

/** @namespace Component/VariantFilters/Container */
export class VariantFiltersContainer extends PureComponent {
    state = {
        openDropdowns: []
    }

    containerProps = () => ({
        activeFilters: this.getActiveFilters()
    })

    containerFunctions = {
        getIsDropdownOpen: this.getIsDropdownOpen.bind(this),
        toggleDropdown: this.toggleDropdown.bind(this),
        closeDropdown: this.closeDropdown.bind(this),
        updateFilter: this.updateFilter.bind(this)
    }

    getActiveFilters() {
        const { filters, configurable_options } = this.props;

        return Object.entries(filters).reduce((acc, [key, value]) => [
            ...acc,
            ...value.map(val => ({
                label: configurable_options?.[key]?.attribute_label,
                value: configurable_options?.[key]?.attribute_options?.[val]?.label,
                code: key,
                val
            }))
        ], []);
    }

    toggleDropdown(code) {
        this.setState(state => ({
            ...state,
            openDropdowns: state.openDropdowns.includes(code) ? state.openDropdowns.filter(e => e !== code) : [ ...state.openDropdowns, code ]
        }));
    }

    closeDropdown(code) {
        this.setState(state => ({
            ...state,
            openDropdowns: state.openDropdowns.filter(e => e !== code)
        }));
    }

    getIsDropdownOpen(code) {
        const { openDropdowns } = this.state;

        return openDropdowns.includes(code);
    }

    updateFilter(code, value) {
        const { updateFilter } = this.props;

        const activeFilters = this.getActiveFilters();

        const isAlreadySelected = activeFilters.some(({ code: c, val: v }) => c === code && v === value);

        if (!isAlreadySelected) {
            this.closeDropdown(code);
        }

        updateFilter(code, value);
    }

    render() {
        return (
            <VariantFilters
                { ...this.props }
                { ...this.containerProps() }
                { ...this.containerFunctions }
            />
        );
    }
}

export default VariantFiltersContainer;