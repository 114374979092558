/* eslint-disable */
import { CONFIGURABLE } from 'Util/Product/Types';

const requestProduct = (args, callback, instance) => {
    const { requestProduct, productSKU, originalSKU } = instance.props;
    const { currentProductSKU } = instance.state;

    /**
     * If URL rewrite was not passed - do not request the product.
     */
    if (!productSKU) {
        return;
    }

    /**
     * Skip loading the same product SKU the second time
     */
    if (currentProductSKU === productSKU) {
        return;
    }

    instance.setState({ currentProductSKU: productSKU });

    const options = {
        isSingleProduct: true,
        args: { filter: instance.getProductRequestFilter() }
    };

    if (originalSKU && originalSKU !== productSKU) {
        const updatedOptions = {
            ...options,
            variantOptions: {
                isSingleProduct: true,
                args: { filter: { productSKU: originalSKU } },
                isVariant: true
            }
        }

        requestProduct(updatedOptions);
        return;
    }

    requestProduct(options);
}

const requestVariant = (args, callback, instance) => {
    const { requestProduct } = instance.props;
    const [ sku ] = args;

    const options = {
        isSingleProduct: true,
        args: { filter: { productSKU: sku } },
        isVariant: true
    };

    requestProduct(options);
}

const requestFullProduct = (args, callback, instance) => {
    const { requestProduct } = instance.props;

    const options = {
        isSingleProduct: true,
        args: { filter: instance.getProductRequestFilter() },
        isAllVariants: true
    };

    requestProduct(options);
}

const componentDidMount = (args, callback, instance) => {
    const result = callback.apply(instance, args);

    const {
        product: {
            variants = []
        } = {}
    } = instance.props;

    const {
        configurableVariantIndex
    } = instance.state;

    if (configurableVariantIndex >= 0) {
        const { sku } = variants[configurableVariantIndex] || {};

        if (sku) {
            instance.requestVariant(sku);
        }
    }

    return result;
}

const componentDidUpdate = (args, callback, instance) => {
    const [prevProps, prevState] = args;

    const {
        originalSKU,
        productSKU,
        product: {
            type_id,
            variants = [],
            sku
        } = {},
        areVariantsLoaded
    } = instance.props;

    const {
        productSKU: prevProductSKU,
        product: {
            variants: prevVariants,
            sku: prevSku
        } = {}
    } = prevProps;

    const {
        configurableVariantIndex,
        didRequestFull = false
    } = instance.state;

    const {
        configurableVariantIndex: prevConfigurableVariantIndex
    } = prevState;

    if ((configurableVariantIndex !== prevConfigurableVariantIndex) && configurableVariantIndex >= 0 && areVariantsLoaded) {
        const { sku } = variants[configurableVariantIndex] || {};

        if (sku) {
            instance.requestVariant(sku);
        }
    }

    if (instance.getAreDetailsLoaded() && type_id === CONFIGURABLE && ((originalSKU === productSKU && sku !== prevSku) || (!areVariantsLoaded && variants.length)) && (!prevVariants || !didRequestFull)) {
        instance.setState(state => ({ ...state, didRequestFull: true }));
        instance.requestFullProduct();
    }

    if (productSKU !== prevProductSKU && didRequestFull) {
        instance.setState(state => ({ ...state, didRequestFull: false }));
    }

    return callback.apply(instance, args);
}

const defaultProps = (originalMember) => ({
    ...originalMember,
    didRequestFull: false
});

const mapStateToProps = (args, callback, instance) => {
    const [ state ] = args;
    return {
        ...callback.apply(instance, args),
        areVariantsLoaded: state.ProductReducer.areVariantsLoaded
    }
}

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            requestProduct,
            requestVariant,
            requestFullProduct,
            componentDidMount,
            componentDidUpdate
        },
        'static-member': {
            defaultProps
        }
    },
    'Route/ProductPage/Container/mapStateToProps': {
        'function': mapStateToProps
    }
}