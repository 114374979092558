// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import TextPlaceholder from 'Component/TextPlaceholder';
import './NewsletterFormPlacholder.style';

/** @namespace Hoeks/NewsletterPage/Component/NewsletterFormPlacholder/Component/NewsletterFormPlacholderComponent */
export class NewsletterFormPlacholderComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        return (
            <div block="NewsletterFormPlacholder">
                <TextPlaceholder></TextPlaceholder>
            </div>
        );
    }
}

export default NewsletterFormPlacholderComponent;
