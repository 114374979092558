import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { fetchMutation, fetchQuery } from 'Util/Request';
import BrowserDatabase from 'Util/BrowserDatabase';
import SparxpresGateway from './SparxpresGateway.component';
import SparxpresQuery from '../../query/Sparxpres.query';
import { SPARXPRES_PAYMENT, SPARXPRES_PAYMENT_TOTALS } from '../../component/Sparxpres/Sparxpres.config';
import { PAYMENT_SUCCESS, PAYMENT_CANCEL } from './SparxpresGateway.config';

/** @namespace Hoeks/Sparxpres/Route/SparxpresGateway/Container/mapStateToProps */
export const mapStateToProps = (state) => {
    // console.log(state);
    return {
        cartTotals: state.CartReducer.cartTotals
    };
};
/** @namespace Hoeks/Sparxpres/Route/SparxpresGateway/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    toggleBreadcrumbs: (state) => dispatch(toggleBreadcrumbs(state)),
    resetCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    ),
    resetGuestCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.resetGuestCart(dispatch)
    ),
});

export const Resolutions = [PAYMENT_SUCCESS, PAYMENT_CANCEL]; 

/** @namespace Hoeks/Sparxpres/Route/SparxpresGateway/Container */
export class SparxpresGatewayContainer extends PureComponent {
    static propTypes = {
        toggleBreadcrumbs: PropTypes.func.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                resolution: PropTypes.string
            })
        }).isRequired
    }

    containerFunctions = {
        setLoading: this.setLoading.bind(this),
        loadOrder: this.loadOrder.bind(this),
        cancelOrder: this.cancelOrder.bind(this),
    };

    loadOrder(){
        this.getOrderData();
    }

    cancelOrder(){
        this.cancelOrderData();
    }

    getOrderData = async () => {
        // const { cartTotals } = this.props;
        const cartTotals = BrowserDatabase.getItem(SPARXPRES_PAYMENT_TOTALS);
        BrowserDatabase.deleteItem(SPARXPRES_PAYMENT_TOTALS);

        // const later = (delay) => {
        //     return new Promise(function(resolve) {
        //         setTimeout(resolve, delay);
        //     });
        // }

        // await later(20000);
        const {paymentData} = await fetchQuery(SparxpresQuery.getSparxpresPaymentSuccessQuery());
        console.log(paymentData)
        if (paymentData){
            this.setState({ order: paymentData, isLoading: false });
        }
        else {
            setLoading(false);
        }

        return { paymentData, cartTotals };
    }

    cancelOrderData = async() => {
        const {paymentData} = await fetchMutation(SparxpresQuery.getSparxpresPaymentCancelMutation());
        if (paymentData){
            this.setState({ order: paymentData, isLoading: false });
        }
        else {
            this.setLoading(false);
        }
    }

    setLoading(isLoading = true) {
        this.setState({ isLoading });
    }

    __construct(props) {
        super.__construct(props);
        const {
            toggleBreadcrumbs,
            match,
            history
        } = props;
        const resolution = Resolutions.indexOf(match.params.resolution) > -1 ? match.params.resolution : null;

        this.state = {
            resolution,
            isLoading: true,
            order: null
        }

        toggleBreadcrumbs(false);

        if (!resolution){
            history.push("/notfound");
        }
    }

    render() {
        return (
            <SparxpresGateway 
                {...this.props}
                {...this.containerFunctions}
                {...this.state}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SparxpresGatewayContainer);