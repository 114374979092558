/* eslint-disable */

// imports the original component from vendor folder
import HoeksProductPrice from 'HoeksComponent/ProductPrice/ProductPrice.component';
export * from 'HoeksComponent/ProductPrice/ProductPrice.component';

// import new extended styles file
import './ProductPrice.override.style.scss';

export class ProductPrice extends HoeksProductPrice { 
    renderOldPrice() {
        const {
            roundedRegularPrice,
            discountPercentage,
            isSchemaRequired,
            variantsCount
        } = this.props;

        const schema = isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : {};

        if (discountPercentage === 0) {
            return null;
        }
        const formatedOldPrice = Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(roundedRegularPrice)
        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
              { ...schema }
            >
                { formatedOldPrice }
            </del>
        );
    }
}

export default ProductPrice;
