/* eslint-disable */
import { PureComponent } from 'react';
import './HelloRetailSearch.style';

export class HelloRetailSearchComponent extends PureComponent {
    render() {
        const { device, isVisible, overlay } = this.props;
        return (
            <div block="HelloRetailSearch" mods={{mobileActive: device.isMobile && isVisible && overlay}} >
                <input id="Search-Input" block="HelloRetailSearch" elem="Input" />
                <span block="HelloRetailSearch" elem="Input-Icon" ></span>
            </div>
        )
    }
}

export default HelloRetailSearchComponent;