/* eslint-disable */
import { withRouter } from 'react-router';
import ProductConfigurableAttributesDetails from '../component/ProductConfigurableAttributesDetails';
import ProductConfigurableAttributesPopup from '../component/ProductConfigurableAttributesPopup';
import ProductConfigurableAttributeDropdown from 'Component/ProductConfigurableAttributeDropdown';

const ProductConfigurableAttributeDropdownWithRouter = withRouter(ProductConfigurableAttributeDropdown);

const renderDropdown = (args, callback, instance) => {
    console.log('debug', args);
    console.log('debug', instance.props);

    return null;
    const result = callback.apply(instance, args);

    if (!result || result.type !== ProductConfigurableAttributeDropdown) {
        return result;
    }

    const {
        product
    } = instance.props;

    const updatedResult = React.createElement(ProductConfigurableAttributeDropdownWithRouter, { ...result.props, product });

    return updatedResult;
}

const render = (args, callback, instance) => {
    if (Array.isArray(instance.__namespaces__) && instance.__namespaces__.includes('Component/CategoryConfigurableAttributes/Component')) {
        return callback.apply(instance, args);
    }

    return (
        <>
            <ProductConfigurableAttributesPopup
                { ...instance.props }
            />
            <ProductConfigurableAttributesDetails
                { ...instance.props }
            />
        </>
    )
}

export default {
    'Component/ProductConfigurableAttributes/Component': {
        'member-function': {
            // renderDropdown
            // render
        }
    }
}