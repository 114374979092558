/* eslint-disable */

/** @namespace Util/Url/updateQueryParamsWithoutHistory */
export const updateQueryParamsWithoutHistory = (pairs, history, location) => {
    const { search, pathname } = location;

    const params = new URLSearchParams(search);
    pairs.forEach(([ name, value ]) => {
        params.set(name, value);
    });
    history.replace(decodeURIComponent(`${ pathname }?${ params }`));
}