/* eslint-disable */

// imports the original component from vendor folder
import { HelloRetailRecommendationBox as SourceHelloRetailRecommendationBox } from '@hoeks/helloretail/src/component/HelloRetailRecommendationBox/HelloRetailRecommendationBox.component';

// import new extended styles file
import './HelloRetailRecommendationBox.override.style.scss';

export class HelloRetailRecommendationBox extends SourceHelloRetailRecommendationBox { 


}

export default HelloRetailRecommendationBox; 
