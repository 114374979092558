/* eslint-disable */

import {ProductAttributeValue as SourceProductAttributeValue} from 'HoeksComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.override.style.scss';

export class ProductAttributeValue extends SourceProductAttributeValue {
    clickHandler(e) {
        const { onClick, attribute, collapse = false } = this.props;

        e.preventDefault();
        onClick(attribute);
        if (collapse) {
            collapse();
        }
    }
}

export default ProductAttributeValue;
