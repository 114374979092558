/* eslint-disable */
import { ProductCard as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';
import { BUNDLE, CONFIGURABLE, GROUPED } from 'Util/Product';
import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import Loader from 'Component/Loader';
import { LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import Fade from 'react-reveal/Fade';
import { productHasSpecialPrice } from 'Util/Product';

import './ProductCard.override.style';

export class ProductCard extends SourceProductCard {
    renderProductPrice() {
        const {
            product: { price_range, type_id, purchase_by_contact, saerligt_god_pris },
            product,
            isConfigurableProductOutOfStock,
            isBundleProductOutOfStock
        } = this.props;

        if (purchase_by_contact && purchase_by_contact != 'Nej') return;

        if (!price_range) {
            return <TextPlaceholder />;
        }

        // switch (type_id) {
        //     case CONFIGURABLE:
        //         if (isConfigurableProductOutOfStock()) {
        //             return this.renderEmptyProductPrice();
        //         }
        //         break;
        //     case BUNDLE:
        //         if (isBundleProductOutOfStock()) {
        //             return this.renderEmptyProductPrice();
        //         }
        //         break;
        //     default:
        //         break;
        // }

        var specialPriceClass = productHasSpecialPrice(product) || saerligt_god_pris ? 'ProductCard-SpecialPrice' : '';

        return (
            <div block="ProductCard" elem="PriceWrapper">
                {this.renderTierPrice()}
                {this.renderProductTypePriceBadge()}
                <ProductPrice
                    price={price_range}
                    mix={{ block: 'ProductCard', elem: 'Price ' + specialPriceClass }}
                    // mix={{ block: 'ProductCard', elem: 'Price' }}
                />
            </div>
        );
    }

    renderBadges() {
        const { product: { saerligt_god_pris, badge_new }, product } = this.props;
        const badgesToRender = [];

        if (saerligt_god_pris && saerligt_god_pris != 'Nej') {
            badgesToRender.push({
                label: __('Blue price'),
                class: 'BadgeSpecialPrice'
            })
        }

        if (productHasSpecialPrice(product)) {
            badgesToRender.push({
                label: __('Tilbud'),
                class: 'BadgeSpecialPrice'
            })
        }

        if (badge_new) {
            badgesToRender.push({
                label: __('New'),
                class: 'BadgeNew'
            })
        }

        return (
            <div block="ProductCard" elem="Badges">
                {badgesToRender.map((badge) => <div block="ProductCard" elem={"Badge " + badge.class} key={ badge.class } data-content={badge.label}>{badge.label}</div>)}
            </div>
        )
    }

    renderBottomBadges() {
        const { product: { badge_limited_edition, badge_new, vigtig_besked } } = this.props;
        const badgesToRender = [];

        if (badge_limited_edition && badge_limited_edition != "0") {

            badgesToRender.push({
                label: __('Limited edition'),
                class: 'BadgeLimitedEdition'
            })
        }
        if (badge_new && badge_new != "0") {
            badgesToRender.push({
                label: __('New'),
                class: 'BadgeNew'
            })
        }
        if(vigtig_besked != '' && vigtig_besked != null) {
            badgesToRender.push({
                label: vigtig_besked,
                class: 'BadgeImportantMessage'
            });
        }

        return (
            <div block="ProductCard" elem="BottomBadges">
                {badgesToRender.map((badge) => <span block="ProductCard" elem={"BottomBadge " + badge.class} data-content={badge.label}>{badge.label}</span>)}
            </div>
        )
    }

    renderVariantBadge() {
        const { product: { variant_badge } } = this.props;
        const badgesToRender = [];

        if (variant_badge) {
            badgesToRender.push({
                label: __('Se flere varianter'),
                class: 'BadgeVariant'
            })
        }

        return (
            <div block="ProductCard" elem="VariantBadges">
                {badgesToRender.map((badge) => <span block="ProductCard" elem={"VariantBadge " + badge.class} data-content={badge.label}>{badge.label}</span>)}
            </div>
        )
    }

    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            {this.renderPicture()}
                        </figure>
                        {this.renderReviews()}
                    </div>
                    <div block="ProductCard" elem="Content">
                        {this.renderBottomBadges()}
                        {this.renderAdditionalProductDetails()}
                        {this.renderMainDetails()}
                        {this.renderProductPrice()}
                        {this.renderVariantBadge()}
                        {this.renderVisualConfigurableOptions()}
                    </div>
                </>
            ))
        );
    }

    render() {
        const {
            children,
            mix,
            isLoading,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions,
            layout,
            setScrollProductId,
            product: {
                id
            } = {}
        } = this.props;

        const mods = {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        };

        if (layout === LIST_LAYOUT) {
            return (
                <li
                    block="ProductCard"
                    mods={mods}
                    mix={mix}
                >
                    <Loader isLoading={isLoading} />
                    {this.renderCardListContent()}
                </li>
            );
        }

        return (
            <Fade duration={750}>
                <li
                    block="ProductCard"
                    mods={{ ...mods, id }}
                    mix={mix}
                    onClick={ setScrollProductId }
                >
                    <Loader isLoading={isLoading} />
                    {this.renderCardContent()}
                    {this.renderProductActions()}
                    <div block="ProductCard" elem="AdditionalContent">
                        {children}
                    </div>
                    {this.renderBadges()}
                </li>
            </Fade>
        );
    }

}

export default ProductCard;