/* eslint-disable */
const _getProductInterfaceFields = (args, callback, instance) => {
    const [ isVariant ] = args;
    const fields = callback.apply(instance, args);
    if (isVariant) {
        fields.push(instance._getCategoriesField());
        fields.push('url');
    }
    return fields;
}

export default {
    'Query/ProductList': {
        'member-function': {
            _getProductInterfaceFields
        }
    }
}