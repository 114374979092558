/* eslint-disable */
import { ProductActions as SourceProductActions } from 'SourceComponent/ProductActions/ProductActions.component';
import ProductPrice from 'Component/ProductPrice';
import AddToCart from 'Component/AddToCart';
import Link from 'Component/Link';
import Field from 'Component/Field';
import ProductConfigurableAttributes from 'Component/ProductConfigurableAttributes';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    GROUPED
} from 'Util/Product';

import './ProductActions.override.style';

export class ProductActions extends SourceProductActions {
    renderSkuAndStock() {
        const {
            product,
            product: { variants },
            productOrVariant: { purchase_by_contact },
            configurableVariantIndex,
            showOnlyIfLoaded,
            displayProductStockStatus
        } = this.props;

        const productOrVariant = variants && variants[configurableVariantIndex] !== undefined
            ? variants[configurableVariantIndex]
            : product;

        const { sku, stock_status } = productOrVariant;

        return (
            <>
                <section
                    block="ProductActions"
                    elem="Section"
                    mods={{ type: 'sku' }}
                    aria-label="Product SKU and availability"
                >
                    {showOnlyIfLoaded(
                        sku,
                        (
                            <>
                                <span block="ProductActions" elem="Sku">
                                    SKU:
                                </span>
                                <span block="ProductActions" elem="Sku" itemProp="sku">
                                    {`${sku}`}
                                </span>
                                {displayProductStockStatus && this.renderStock(stock_status)}
                            </>
                        ),
                        <TextPlaceholder />
                    )}
                </section>
                {
                    purchase_by_contact && purchase_by_contact != 'Nej' ?
                        <section block="ProductActions"
                            elem="Section"
                            mods={{ type: 'kontakt' }}>
                            <p>{__('This product is not sold online')}</p>
                        </section> : <></>
                }
            </>
        );
    }

    renderConfigurableAttributes() {
        const {
            getLink,
            updateConfigurableVariant,
            parameters,
            areDetailsLoaded,
            product: { configurable_options, type_id, attributes, variants },
            productOrVariant: {
                // price_range: { 
                //     maximum_price: { 
                //         discount: { 
                //             amount_off, percent_off 
                //         } 
                //     } 
                // }, 
                attributes: variant_attributes,
                special_from_date,
                special_to_date
            },
            productOrVariant,
            product,
            getIsConfigurableAttributeAvailable,
            areVariantsLoaded
        } = this.props;

        var hasSpecialPrice = false;

        if (productOrVariant.price_range != undefined) {
            if (productOrVariant.price_range.maximum_price != undefined) {
                const {
                    price_range: {
                        maximum_price: {
                            discount: {
                                amount_off, percent_off
                            }
                        }
                    }
                } = productOrVariant

                hasSpecialPrice = amount_off || percent_off ? true : false
            }
        }

        if (type_id !== 'configurable') {
            return null;
        }

        // var hasSpecialPrice = amount_off || percent_off ? true : false

        return (
            <div
                ref={this.configurableOptionsRef}
                block="ProductActions"
                elem="AttributesWrapper"
            >
                <ProductConfigurableAttributes
                    // eslint-disable-next-line no-magic-numbers
                    numberOfPlaceholders={[2, 4]}
                    mix={{ block: 'ProductActions', elem: 'Attributes' }}
                    isReady={areDetailsLoaded}
                    getLink={getLink}
                    parameters={parameters}
                    updateConfigurableVariant={updateConfigurableVariant}
                    configurable_options={configurable_options}
                    getIsConfigurableAttributeAvailable={getIsConfigurableAttributeAvailable}
                    isContentExpanded
                    product={product}
                    variants={variants}
                    attributes={attributes}
                    hasSpecialPrice={hasSpecialPrice}
                    special_from_date={special_from_date}
                    special_to_date={special_to_date}
                    variantAttributes={variant_attributes}
                    areVariantsLoaded={ areVariantsLoaded }
                />
            </div>
        );
    }

    renderQuantityInput() {
        const {
            product,
            quantity,
            maxQuantity,
            minQuantity,
            setQuantity,
            product: { type_id },
            productOrVariant: { purchase_by_contact }
        } = this.props;

        if (type_id === GROUPED || purchase_by_contact && purchase_by_contact != 'Nej') {
            return null;
        }

        return (
            <Field
                id="item_qty"
                name="item_qty"
                type="number"
                value={quantity}
                max={maxQuantity}
                min={minQuantity}
                mix={{ block: 'ProductActions', elem: 'Qty' }}
                onChange={setQuantity}
            />
        );
    }

    renderAddToCart() {
        const {
            configurableVariantIndex,
            product,
            productOrVariant: { purchase_by_contact },
            quantity,
            groupedProductQuantity,
            onProductValidationError,
            productOptionsData,
            product: { type_id = '' }
        } = this.props;

        return (
            <>
                {
                    purchase_by_contact && purchase_by_contact != 'Nej' ?
                        <Link
                            to="/kontakt"
                            mix={{ block: 'ProductActions', elem: 'LinkToContact Button AddToCart' }}
                        >
                            {__("THIS PRODUCT CAN'T BE PURCHASED ONLINE")}
                        </Link>
                        :
                        configurableVariantIndex < 0 && type_id === 'configurable' ?
                        <button
                            block="ProductActions-AddToCart Button AddToCart"
                            disabled={ true }
                        >
                            <span>{ __('Add to cart') }</span>
                            <span>{ __('Adding...') }</span>
                        </button>
                        :
                        <AddToCart
                            product={product}
                            configurableVariantIndex={configurableVariantIndex}
                            mix={{ block: 'ProductActions', elem: 'AddToCart' }}
                            quantity={quantity}
                            groupedProductQuantity={groupedProductQuantity}
                            onProductValidationError={onProductValidationError}
                            productOptionsData={productOptionsData}
                        />
                }
            </>
        );
    }

    renderPriceWithSchema() {
        const {
            productPrice,
            offerCount,
            productOrVariant: {
                price_range,
                saerligt_god_pris,
                attributes,
                purchase_by_contact,
                special_from_date,
                special_to_date
            },
            productOrVariant,
            checkSpecialPrice
        } = this.props;

        if (price_range == undefined || purchase_by_contact && purchase_by_contact != 'Nej') return;

        const hasSpecialPrice = checkSpecialPrice();
        var renderBadge = false;
        var label = ''

        if (saerligt_god_pris || hasSpecialPrice) {
            renderBadge = true;
            label = hasSpecialPrice ? __('Tilbud') : __('Blue price');
        }

        const {
            minimum_price: {
                final_price: {
                    value: minFinalPrice = 0
                } = {}
            } = {},
            maximum_price: {
                final_price: {
                    value: maxFinalPrice = 0
                } = {}
            } = {}
        } = price_range;

        return (
            <div
                block="ProductActions"
                elem="PriceWrapper"
            >
                {this.renderConfigurablePriceBadge()}
                {this.renderSchema()}
                <meta
                    itemProp="highPrice"
                    content={(minFinalPrice === maxFinalPrice) ? minFinalPrice : maxFinalPrice}
                />
                <div block="ProductActions" elem="PriceContainer">
                    <ProductPrice
                        isSchemaRequired
                        variantsCount={offerCount}
                        price={price_range}
                        mix={{ block: 'ProductActions', elem: 'Price' }}
                    />
                    {renderBadge ? <p block="ProductActions" elem="PriceLabel" data-content={label}> {label} </p> : <></>}
                </div>
            </div>
        );
    }

    renderBrandAndDesinger() {
        const {
            productOrVariant: {
                categories,
                attributes: {
                    brands: {
                        attribute_value: brandValue,
                        attribute_options: brandOptions
                    } = {},
                    designer: {
                        attribute_value: designerValue,
                        attribute_options: designerOptions
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!brandValue || !designerValue) {
            return null;
        }

        const {
            [brandValue]: {
                label: brandLabel
            } = {}
        } = brandOptions;

        const {
            [designerValue]: {
                label: designerLabel
            } = {}
        } = designerOptions;

        if (!brandLabel || !designerLabel) {
            return null;
        }

        const brandUrl = this.getCategoryUrl(categories, brandLabel);
        const designerUrl = this.getCategoryUrl(categories, designerLabel);;

        return (
            <div block="ProductActions" elem="BrandAndDesigner">
                <Link to={brandUrl}>
                    <p>{__('Brand: ') + brandLabel + ' '}</p>
                </Link>
                <p>/</p>
                <Link to={designerUrl}>
                    <p> {__('Designer: ') + designerLabel}</p>
                </Link>
            </div>
        );

        // const { productOrVariant: { attributes, categories } } = this.props;

        // if (attributes == undefined) return;
        // if (attributes.brand == undefined && attributes.designer == undefined) return;
        // const {
        //     brands: {
        //         attribute_value: brandValue,
        //         attribute_options: brandOptions
        //     },
        //     designer: {
        //         attribute_value: designerValue,
        //         attribute_options: designerOptions
        //     }
        // } = attributes;
        // const brandUrl = this.getCategoryUrl(categories, brandOptions[brandValue].label);
        // const designerUrl = this.getCategoryUrl(categories, designerOptions[designerValue].label);
        // return (
        //     <div block="ProductActions" elem="BrandAndDesigner">
        //         <Link to={brandUrl}>
        //             <p>{__('Brand: ') + brandOptions[brandValue].label + ' '}</p>
        //         </Link>
        //         <p>/</p>
        //         <Link to={designerUrl}>
        //             <p> {__('Designer: ') + designerOptions[designerValue].label}</p>
        //         </Link>
        //     </div>
        // )
    }

    getCategoryUrl(categories = [], name) {
        var categoryUrl = ''
        categories.forEach(category => {
            const {
                name: categoryName = '',
                url = ''
            } = category;
            if (categoryName == name) {
                categoryUrl = url;
            }
        });

        return categoryUrl;
    }

    renderStockStatus() {
        const {
            product,
            productOrVariant: {
                leveringtid_ny,
                attributes
            }
        } = this.props;

        const deliveryTime = leveringtid_ny != null && attributes != undefined && attributes.leveringtid_ny.attribute_options[leveringtid_ny] != undefined ? attributes.leveringtid_ny.attribute_options[leveringtid_ny].label : '';

        if (!deliveryTime) {
            return null;
        }

        return (
            <div block="ProductActions" elem="StockStatus">
                <p>{__('Delivery time: ')} {deliveryTime != null ? deliveryTime : ''}</p>
            </div>
        )
    }

    renderImportantMessage() {
        const {
            productOrVariant
        } = this.props;

        if (productOrVariant.vigtig_besked == undefined) return;
        const { vigtig_besked } = productOrVariant;
        if (vigtig_besked == '' || vigtig_besked == null) return;

        return <p block="ProductActions" elem="ImportantMessage">{vigtig_besked}</p>
    }

    renderDiscountPeriod() {
        const {
            specialPricePeriod: {
                hasSpecialPricePeriod,
                specialPriceFrom,
                specialPriceTo
            } = {}
        } = this.props;

        if (!hasSpecialPricePeriod) {
            return null;
        }

        return (
            <p block="ProductActions" elem="DiscountPeriod">
                { __('Special price period: %s - %s', specialPriceFrom, specialPriceTo) }
            </p>
        );
    }

    renderChooseVariantMessage() {
        const {
            product: {
                type_id
            } = {},
            configurableVariantIndex,
        } = this.props;

        if (type_id != 'configurable' || configurableVariantIndex > -1) {
            return;
        }

        return(
            <div block="ProductActions" elem="ChoseVariantMessage">
                {__('Choose a variant to add the product to cart')}
            </div>
        );
    }

    render() {
        return (
            <article block="ProductActions">
                {this.renderPriceWithGlobalSchema()}
                {this.renderStockStatus()}
                {this.renderShortDescription()}
                {this.renderDownloadableProductSample()}
                {this.renderDownloadableProductLinks()}
                {this.renderChooseVariantMessage()}
                <div
                    block="ProductActions"
                    elem="AddToCartWrapper"
                    mix={{ block: 'FixedElement', elem: 'Bottom' }}
                >
                    {this.renderQuantityInput()}
                    {this.renderAddToCart()}
                    {this.renderProductCompareButton()}
                    {this.renderProductWishlistButton()}
                </div>
                {this.renderReviews()}
                {this.renderNameAndBrand()}
                {this.renderBrandAndDesinger()}
                {this.renderSkuAndStock()}
                {this.renderConfigurableAttributes()}
                {this.renderImportantMessage()}
                {this.renderDiscountPeriod()}
                {this.renderCustomizableOptions()}
                {this.renderBundleItems()}
                {this.renderGroupedItems()}
                {this.renderTierPrices()}
            </article>
        );
    }

}

export default ProductActions;