/* eslint-disable */

 import { ProductListContainer as HoeksProductListContainer } from 'HoeksComponent/ProductList/ProductList.container';
 import { withRouter } from 'react-router-dom';
 import { connect } from 'react-redux';
 import ProductListInfoDispatcher from 'Store/ProductListInfo/ProductListInfo.dispatcher';

 /** @namespace Component/ProductList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Component/ProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestProductListInfo: (options) => ProductListInfoDispatcher.handleData(dispatch, options)
});

 /** @namespace Component/ProductList/Container */
export class ProductListContainer extends HoeksProductListContainer {

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductListContainer));
