// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import TextPlaceholder from 'Component/TextPlaceholder';
import './CategoryBlockPlaceholder.style';

/** @namespace Hoeks/Categoryblock/Component/CategoryBlockPlaceholder/Component/CategoryBlockPlaceholderComponent */
export class CategoryBlockPlaceholderComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        return (
            <div block="CategoryBlockPlaceholder">
                <TextPlaceholder></TextPlaceholder>
            </div>
        );
    }
}

export default CategoryBlockPlaceholderComponent;
