/* eslint-disable */

// imports the original component from vendor folder
import SourceAddToWishlistButton from '@hoeks/local-wishlist/src/component/AddToWishlistButton/AddToWishlistButton.component';

// import new extended styles file
import './AddToWishlistButton.override.style.scss';

export class AddToWishlistButton extends SourceAddToWishlistButton { 

}

export default AddToWishlistButton; 
