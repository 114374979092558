// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Link from 'Component/Link';
import CmsBlock from 'Component/CmsBlock';

import './DiscountSign.style';

/** @namespace Hoeks/DiscountSign/Component/DiscountSign/Component/DiscountSignComponent */
export class DiscountSignComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        const { show } = this.props;
        return (
            <div block="DiscountSign">
                <div block="DiscountSign" elem="first">
                    {show ? <CmsBlock identifier="discount-sign" blockType="discountSign" /> : <></>}
                </div>
                <div block="DiscountSign" elem="second">
                    <CmsBlock identifier="second-discount-sign" blockType="secondDiscountSign"/>
                </div>
            </div>
        );
    }
}

export default DiscountSignComponent;
