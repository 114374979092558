/* eslint-disable */
import { HelloRetailSearchComponent as SourceHelloRetailSearchComponent } from '@hoeks/helloretail/src/component/HelloRetailSearch/HelloRetailSearch.component';

import './HelloRetailSearch.override.style.scss';

export class HelloRetailSearchComponent extends SourceHelloRetailSearchComponent {
    render() {
        const { device } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <div block="HelloRetailSearch" >
                <input id="Search-Input" block="HelloRetailSearch" elem="Input" />
                <span block="HelloRetailSearch" elem="Input-Icon" ></span>
            </div>
        )
    }
}

export default HelloRetailSearchComponent;