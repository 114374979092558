// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Field from 'Component/Field';
import { SELECT_TYPE } from 'Component/Field/Field.config';

import './FurnitureHouesePicker.style';

/** @namespace Hoeks/Shipping_furniture_house/Component/FurnitureHouesePicker/Component/FurnitureHouesePickerComponent */
export class FurnitureHouesePickerComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderOptions() {
        const { options, onSelect } = this.props;
        return (
            <Field
                type={ SELECT_TYPE }
                label={__("Furniture Houses")}
                selectOptions={ options }
                keepOrder={true}
                onChange={ onSelect }
            />
        );
    }

    render() {
        const { active, options } = this.props;
        
        if (!active) return null;
        return (
            <div block="FurnitureHouesePicker">
                <h2 block="FurnitureHouesePicker" elem="Heading">{__('Select furniture house')}</h2>
                <div block="FurnitureHouesePicker" elem="Inputs">
                    {this.renderOptions()}
                </div>
            </div>
        );
    }
}

export default FurnitureHouesePickerComponent;
