/* eslint-disable */
import Popup from 'Component/Popup';
import VariantFilters from '../VariantFilters';
import VariantProductList from '../VariantProductList';
import TextPlaceholder from 'Component/TextPlaceholder';
import { CONFIGURABLE } from 'Util/Product';
import { PRODUCT_CONFIGURABLE_ATTRIBUTES_POPUP_ID, MOBILE_INFINITE_SCROLL_CONTAINER_ID } from './ProductConfigurableAttributesPopup.config';

import './ProductConfigurableAttributesPopup.style';

/** @namespace Component/ProductConfigurableAttributesPopup/Component */
export class ProductConfigurableAttributesPopup extends PureComponent {
    renderWrapper(children) {
        return (
            <div
                block="ProductConfigurableAttributesPopup"
                elem="Wrapper"
            >
                { children }
            </div>
        );
    }

    renderPlaceholder() {
        return (
            <TextPlaceholder length="custom"/>
        );
    }

    renderSelectorButton() {
        const { onPopupButtonClick } = this.props;

        return (
            <button
                block="ProductConfigurableAttributesPopup"
                elem="SelectorButton"
                onClick={ onPopupButtonClick }
            >
                { __('Configure') }
            </button>
        );
    }

    renderFilters() {
        const {
            updateFilter,
            configurable_options,
            areVariantsLoaded,
            filters,
            customFilters,
            enabledCustomFilters,
            getQtyPreview,
        } = this.props;

        return (
            <VariantFilters
                updateFilter={ updateFilter }
                configurable_options={ configurable_options }
                isLoading={ !areVariantsLoaded }
                filters={ filters }
                customFilters={ customFilters }
                enabledCustomFilters={ enabledCustomFilters }
                getQtyPreview={ getQtyPreview }
            />
        );
    }

    renderProductList() {
        const {
            filteredVariants = [],
            configurable_options,
            onVariantSelect,
            updateConfigurableVariantFull,
            areVariantsLoaded
        } = this.props;

        return (
            <VariantProductList
                variants={ filteredVariants }
                configurable_options={ configurable_options }
                onVariantSelect={ onVariantSelect }
                updateConfigurableVariantFull={ updateConfigurableVariantFull }
                isLoading={ !areVariantsLoaded }
                mobileInfiniteScrollId={ MOBILE_INFINITE_SCROLL_CONTAINER_ID }
            />
        );
    }

    renderPopup() {
        const { keyPrefix, filteredVariants } = this.props;

        const key = filteredVariants.reduce((acc, { id }) => `${acc}-${id}`, `${keyPrefix}_FILTERS_ID_ORDER`);

        return (
            <Popup
                id={ PRODUCT_CONFIGURABLE_ATTRIBUTES_POPUP_ID }
                key={ key }
                clickOutside={ true }
                mix={ { block: 'ProductConfigurableAttributesPopup', elem: 'Popup' } }
                contentMix={ { block: 'ProductConfigurableAttributesPopup', elem: 'PopupContent' } }
            >
                <div
                    block="ProductConfigurableAttributesPopup"
                    elem="ContentWrapper"
                    id={ MOBILE_INFINITE_SCROLL_CONTAINER_ID }
                >
                    { this.renderFilters() }
                    { this.renderProductList() }
                </div>
            </Popup>
        );
    }

    render() {
        const {
            areDetailsLoaded,
            product: { type_id }
        } = this.props;

        if (!areDetailsLoaded) {
            return this.renderWrapper(
                this.renderPlaceholder()
            );
        }

        if (type_id !== CONFIGURABLE) {
            return null;
        }

        return this.renderWrapper(
            <>
                { this.renderPopup() }
                { this.renderSelectorButton() }
            </>
        );
    }
}

export default ProductConfigurableAttributesPopup;