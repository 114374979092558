/**
 * HelloRetail scripts
 */
 class Scripts {
    static getScript(id) {
        var aws = document.createElement('script');
        aws.type = 'text/javascript';
        if (typeof(aws.async) != "undefined") { aws.async = true; }
        aws.src = `https://d1pna5l3xsntoj.cloudfront.net/scripts/company/awAddGift.js#${id}`;
        return aws;
    }
}

export default Scripts;
