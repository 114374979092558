/* eslint-disable */
import SparxpresWidget from "../component/SparxpresWidget";
import SparxpresWidgetPlaceholder from "../component/SparxpresWidgetPlaceholder";
export const SPARXPRES_WIDGET_TYPE = 'HoeksSparxpres'
const addNewsletterWidget = (member, instance) => {
    return {
        ...member,
        [SPARXPRES_WIDGET_TYPE]: {
            component: SparxpresWidget,
            fallback: () => <SparxpresWidgetPlaceholder />
        }
    }
}

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': {
                implementation: addNewsletterWidget,
                position: 1
            }
        }
    },
}