/* eslint-disable */
const awTrack = (args, callback, instance) => {
    const { product: { clickTrack } } = instance.props;
    if (clickTrack && ADDWISH_PARTNER_NS){
        ADDWISH_PARTNER_NS.api.tracking.track_click(clickTrack);
    }
    return callback(...args);
}

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            registerSharedElement: awTrack
        }
    }
}