import { createRef, PureComponent } from 'react';
import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';
import DiscountSign from '../component/DiscountSign';

const HIDE_DISCOUNT_SIGN = 'hideDiscountSign';

const renderDiscountSign = (member, context) => {
    return [
        ...member,
        {
            component: <DiscountSign />,
            position: 40
        }
    ]
}

const parseDate = (date) => {
    if (date == null) return null
    const dateArr = date.split(/[^0-9]/);
    const parsedDate = new Date(dateArr[0], dateArr[1] - 1, dateArr[2], dateArr[3], dateArr[4], dateArr[5])
    return parsedDate;
}

const hasSpecialPrice = (price_range, fromDate, toDate) => {
    if (price_range == undefined) return false;
    const {maximum_price} = price_range;
    if (maximum_price == undefined) {
        return false;
    }
    const {
        maximum_price: {
            discount: {
                amount_off, percent_off
            }
        }
    } = price_range;

    const today = new Date();

    if (amount_off || percent_off) {
        if (parseDate(fromDate) <= today && parseDate(toDate) >= today) {
            return true;
        }

        if (parseDate(fromDate) <= today && toDate == null) {
            return true;
        }

        if (fromDate == null && parseDate(toDate) >= today) {
            return true;
        }

        if (fromDate == null && toDate == null) {
            return true;
        }
    }

    return false;
}

const addClass = (className) => (args, callback, instance) => {
    const {
        productOrVariant: {
            saerligt_god_pris,
            price_range,
            special_from_date,
            special_to_date,

        }
    } = instance.props;
    if (saerligt_god_pris != null && saerligt_god_pris != undefined && saerligt_god_pris != 0) {
        document.body.classList.add(className);
    } else if (hasSpecialPrice(price_range, special_from_date, special_to_date)) {
        document.body.classList.add(className);
    } else {
        document.body.classList.remove(className);
    }
    return callback.apply(instance, args);
}

const removeClass = (className) => (args, callback, instance) => {
    document.body.classList.remove(className);
    return callback.apply(instance, args);
}

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: {
                implementation: renderDiscountSign,
                position: 0
            }
        }
    },
    'Route/ProductPage/Component': {
        'member-function': {
            'componentDidMount': addClass(HIDE_DISCOUNT_SIGN),
            'componentDidUpdate': addClass(HIDE_DISCOUNT_SIGN),
            'componentWillUnmount': removeClass(HIDE_DISCOUNT_SIGN)
        }
    }
}