import { Field } from 'Util/Query';
import CheckoutQuery from 'Query/Checkout.query';

/** @namespace Hoeks/Sparxpres/Query/Sparxpres */
export class SparxpresQuery {
    getCreateSparxpresPaymentLinkMutation() {
        return new Field('s_createSparxpresPaymentLink')
            .addFieldList(this._getSparxpresLinkFields())
            .setAlias('sparxpresLink');
    }

    getSparxpresPaymentSuccessQuery(){
        return new Field('s_sparxpresPaymentSuccess')
            .addFieldList(this._getSparxpresPaymentSuccessFields())
            .setAlias('paymentData');
    }

    getSparxpresPaymentCancelMutation(){
        return new Field('s_sparxpresPaymentCancel')
            .addFieldList(this._getSparxpresPaymentCancelFields())
            .setAlias('orderData');
    }


    _getSparxpresLinkFields() {
        return ['payment_link'];
    }

    _getSparxpresPaymentSuccessFields() {
        return ['order_id']
    }

    _getSparxpresPaymentCancelFields() {
        return ['order_id'];
    }

    // getTotals(){
    //     const totals = CheckoutQuery.getTotalsField();
    //     console.log(query)
    //     // query._fieldList.items._fieldList.product.addField(ProductListQuery._getCategoriesField());
    //     console.log(query);
    //     return query;
    // }

}

export default new SparxpresQuery();
