import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import HelloRetail from './HelloRetail.component';

const mapStateToProps = state => ({
    partner_id: state.ConfigReducer?.helloretail?.partner_id,
    enabled: state.ConfigReducer?.helloretail?.enabled,
    tracking_cart: state.ConfigReducer?.helloretail?.tracking_cart,
    tracking_conversion: state.ConfigReducer?.helloretail?.tracking_conversion,
    canonical_url: state.MetaReducer?.canonical_url,
    email: state.CheckoutReducer?.email,
    customer: state.MyAccountReducer?.customer,
    state
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HelloRetail));
