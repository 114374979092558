/* eslint-disable */
import { appendWithStoreCode, generateQuery, getQueryParam, convertKeyValuesToQueryString, convertQueryStringToKeyValuePairs } from 'Util/Url';
import history from 'Util/History';

const render = (args, callback, instance) => {
    const {
        selectValue
    } = instance.props;

    if (selectValue === '') {
        return callback.apply(instance, args);
    }

    return (
        <>
            { callback.apply(instance, args) }
            { instance.renderRemoveOption() }
        </>
    )
}

const renderRemoveOption = (args, callback, instance) => {
    const {
        selectValue,
        onChange,
        option: {
            attribute_code
        } = {},
        parameters,
        location: {
            pathname
        } = {},
        product: {
            url
        } = {},
        location,
        history: propHistory,
        product
    } = instance.props;

    const getPropHistory = () => {
        if (getQueryParam(attribute_code, location) !== false) {
            const keyValuePairs = convertQueryStringToKeyValuePairs(propHistory.location.search);
            const updatedKeyValuePairs = Object.fromEntries(Object.entries(keyValuePairs).filter(([key]) => key !== attribute_code));
            const updatedQuery = convertKeyValuesToQueryString(updatedKeyValuePairs);
            const search = updatedQuery.length ? `?${updatedQuery}` : '';

            return {
                ...propHistory,
                location: {
                    ...propHistory.location,
                    search
                }
            }
        }

        return propHistory;
    }

    const query = generateQuery(Object.fromEntries(Object.entries(parameters).filter(([key]) => key !== attribute_code)), location, getPropHistory());

    const changeProduct = () => history.push({
        pathname: appendWithStoreCode(url),
        state: { product },
        search: query
    });


    const removeOption = () => onChange(selectValue);

    const onClick = pathname !== url ? changeProduct : removeOption;

    return (
        <button
            block="ProductConfigurableAttributeDropdown"
            elem="RemoveOption"
            mix={ { block: 'Button' } }
            onClick={ onClick }
        >
            X
        </button>
    )
}

export default {
    'Component/ProductConfigurableAttributeDropdown/Component': {
        'member-function': {
            // render,
            // renderRemoveOption
        }
    }
}