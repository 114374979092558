export const getHRIndexedProduct = (product) => {
    const { url, currency, imageUrl: image, title: name, productNumber: sku, extraData: { itemNumber: id }, oldPrice: price, price: minPrice  } = product;
    const urlSplit = url.split('#aw_source=');
    const producturl = new URL(urlSplit[0]);
    const track = urlSplit[1];
    return {
        sku: sku,
        placeholder: true,
        id: id,
        name: name,
        clickTrack: track,
        small_image: {
            url: image
        },
        thumbnail: {
            url: image
        },
        price_range: {
            minimum_price: {
                discount: {
                    amount_off: price-minPrice,
                    percent_off: (1-minPrice/price)*100
                },
                final_price: {
                    currency: currency,
                    value: minPrice
                },
                final_price_excl_tax: {
                    currency: currency,
                    value: minPrice
                },
                regular_price: {
                    currency: currency,
                    value: price
                },
                regular_price_excl_tax: {
                    currency: currency,
                    value: price
                }
            }
        },
        url: producturl['pathname']
    };
}
