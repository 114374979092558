/* eslint-disable */
import { listenForBroadCast } from 'Util/Request/Request';

const beforeRequestPage = (args, callback, instance) => {
    instance.dataModelName = 'CmsPage';
    listenForBroadCast(instance.dataModelName).then(
        (data) => {
            instance.onPageLoad(data);
        },
    );
    return callback(...args);
}

export default {
    'Route/CmsPage/Container': {
        'member-function': {
            requestPage: beforeRequestPage
        }
    }
}
