/* eslint-disable */
import VariantFilterOption from './VariantFilterOption.component';

/** @namespace Component/VariantFilterOption/Container */
export class VariantFilterOptionContainer extends PureComponent {
    containerFunctions = {
        onClick: this.onClick.bind(this)
    }

    containerProps = () => ({
        isChecked: this.isChecked()
    })

    onClick() {
        const {
            attribute: {
                attribute_code
            },
            option: {
                value
            },
            updateFilter
        } = this.props;

        updateFilter(attribute_code, value);
    }

    isChecked() {
        const {
            attribute: {
                attribute_code
            },
            option: {
                value: optionValue
            },
            filters
        } = this.props;

        const isChecked = Object.entries(filters).map(([key, value]) => key === attribute_code && value.includes(optionValue)).reduce((acc, cur) => acc || cur, false);

        return isChecked;
    }

    render() {
        return (
            <VariantFilterOption
                { ...this.props }
                { ...this.containerFunctions }
                { ...this.containerProps() }
            />
        );
    }
}

export default VariantFilterOptionContainer;