// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Snapengage from './snapengage.component';

/** @namespace Hoeks/Snapengage/Component/Snapengage/Container/SnapengageContainer */
export class SnapengageContainer extends PureComponent {
    state = {
        chatAgentOnline: false,
        hasCheckStatusRun: false
    }

    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
        contactButtonClick: this.contactButtonClick.bind(this),
        contactPopoutCloseClick: this.contactPopoutCloseClick.bind(this),
        startChatButtonClick: this.startChatButtonClick.bind(this)
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    componentDidMount() {

    }
    
    componentDidUpdate() {

    }

    contactButtonClick() {
        const { hasCheckStatusRun } = this.state;
        const contactPopout = document.getElementsByClassName('contact-popout')[0];
        const contactPopoutCurtain = document.getElementsByClassName('contact-popout-curtain')[0];
        contactPopout.style.display = 'block';
        contactPopoutCurtain.style.display = 'block';

        if (hasCheckStatusRun == false && window.SnapEngage != undefined) {
            this.checkChatStatus();
            this.setState({hasCheckStatusRun: true});
        } else if (window.SnapEngage == undefined) {
            // console.log('test','window.snapengage is undefined');
            this.handelUndefinedSnapengage();
        }
    }

    contactPopoutCloseClick() {
        const contactPopout = document.getElementsByClassName('contact-popout')[0];
        const contactPopoutCurtain = document.getElementsByClassName('contact-popout-curtain')[0];
        contactPopout.style.display = 'none';
        contactPopoutCurtain.style.display = 'none';
    }

    startChatButtonClick() {
        const { chatAgentOnline } = this.state;
        const contactPopout = document.getElementsByClassName('contact-popout')[0];
        const contactPopoutCurtain = document.getElementsByClassName('contact-popout-curtain')[0];
        if (chatAgentOnline) {
            contactPopout.style.display = 'none';
            contactPopoutCurtain.style.display = 'none';
            window.SnapEngage.startLink();
        }
    }

    handelUndefinedSnapengage() {
        var handelUndefinedSnapengageTimeout;
        clearTimeout(handelUndefinedSnapengageTimeout);
        // console.log('test', 'checking if window.SnapEngage is undefined');
        if (window.SnapEngage != undefined) {
            this.checkChatStatus();
            this.setState({hasCheckStatusRun: true});
        } else {
            // console.log('test','window.snapengage is undefined');
            handelUndefinedSnapengageTimeout = setTimeout(this.handelUndefinedSnapengage.bind(this), 5000);
        }
    }

    checkChatStatus() {
        const self = this;
        const { chatAgentOnline } = this.state;
        var checkChatAgentStatusTimeout;
        var agentStatus = chatAgentOnline;
        // const contactPopout = document.getElementsByClassName('contact-popout')[0];
        // const contactPopoutCurtain = document.getElementsByClassName('contact-popout-curtain')[0];

        window.SnapEngage.getAgentStatusAsync(function (online) {
            if (self.constructor.name == "SnapengageContainer") {
                agentStatus = online;
            }
        });    
        if (chatAgentOnline != agentStatus) {
            this.setState({chatAgentOnline: agentStatus});
        }

        clearTimeout(checkChatAgentStatusTimeout)
        checkChatAgentStatusTimeout = setTimeout(this.checkChatStatus.bind(this), 2.2 * 60 * 1000);
        // checkChatAgentStatusTimeout = setTimeout(this.checkChatStatus.bind(this), 5000);
    }

    render() {
        return (
            <Snapengage
                {...this.state}
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default SnapengageContainer;
