/* eslint-disable */
import LocalWishlistPage from './LocalWishlistPage.component';
import { connect } from 'react-redux';
import ProductListQuery from 'Query/ProductList.query';
import DataContainer from 'Util/Request/DataContainer';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';

/** @namespace Route/LocalWishlistPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    wishlist: state.LocalWishlistReducer.wishlist
});

/** @namespace Route/LocalWishlistPage/Container/mapStateToProps */
export const mapDispatchToProps = (dispatch) => ({
    disableBreadcrumbs: () => dispatch(toggleBreadcrumbs(false))
})

/** @namespace Route/LocalWishlistPage/Container */
export class LocalWishlistPageContainer extends DataContainer {
    state = {
        wishlistProducts: [],
        isLoading: true
    }

    componentDidMount() {
        const {
            disableBreadcrumbs,
            wishlist
        } = this.props;

        disableBreadcrumbs();

        if (wishlist && wishlist.length > 0) {
            this.getWishlistProducts();
        } else {
            this.setState(state => ({
                ...state,
                isLoading: false,
                wishlistProducts: []
            }))
        }
    }

    componentDidUpdate(prevProps) {
        const {
            wishlist
        } = this.props;

        const {
            wishlist: prevWishlist
        } = prevProps;

        if (prevWishlist && wishlist && prevWishlist.length !== wishlist.length) {
            if (wishlist.length > 0) {
                this.getWishlistProducts();
            } else {
                this.setState(state => ({
                    ...state,
                    isLoading: false,
                    wishlistProducts: []
                }))
            }
        }
    }

    getWishlistProducts() {
        if (this.promise) {
            this.promise.cancel();
        }

        const {
            wishlist
        } = this.props;

        const wishlistSkus = wishlist.map(({ sku }) => sku);

        this.setState(state => ({
            ...state,
            isLoading: true
        }), () => {
            this.fetchData(
                this.getQuery(wishlistSkus),
                (response) => {
                    const {
                        products: {
                            items = []
                        } = {}
                    } = response;

                    console.log(response);

                    this.setState(state => ({
                        ...state,
                        wishlistProducts: items,
                        isLoading: false
                    }));
                },
                (error) => {
                    console.log(error);
                }
            );
        })
    }

    getQuery(skus) {
        return [
            ProductListQuery.getQuery({
                args: {
                    filter: {
                        productsSkuArray: skus
                    }
                },
                notRequireInfo: true
            })
        ];
    }

    render() {
        return (
            <LocalWishlistPage
                { ...this.props }
                { ...this.state }
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalWishlistPageContainer);