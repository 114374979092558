import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { Field } from 'Util/Query';

const addHelloRetailConfigFields = (args, callback, instance) => {
    const hr = new Field('helloretail')
    .addFieldList(['enabled','partner_id','tracking_cart','tracking_conversion']);
    return [
        ...callback(args),
        hr
    ];
}

const addHRToConfigReducerInitialState = (args, callback, instance) => {
    const { storeConfig: { helloretail } } = BrowserDatabase.getItem('config') || { storeConfig: { helloretail: {}} };
    return {
        ...callback(...args),
        helloretail
    };
};

const addHRToConfigUpdate = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { config: { storeConfig: { helloretail = {} } } = { storeConfig: { helloretail: {}} }, type } = action;
    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }
    const safehr = helloretail || {}; 
    return {
        ...originalUpdatedState,
        helloretail: safehr
    };
};


export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: addHelloRetailConfigFields
        }
    },
    'Store/Config/Reducer/getInitialState': {
        'function': addHRToConfigReducerInitialState
    },
    'Store/Config/Reducer': {
        'function': addHRToConfigUpdate
    },
};
