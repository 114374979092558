/* eslint-disable */

// imports the original component from vendor folder
import HoeksPopup from 'HoeksComponent/Popup/Popup.component';
export * from 'HoeksComponent/Popup/Popup.component';
import history from 'Util/History';
import { ESCAPE_KEY } from './Popup.config';

// import new extended styles file
import './Popup.override.style.scss';

export class Popup extends HoeksPopup { 
    onVisible() {
        const { onVisible } = this.props;

        this.freezeScroll();
        this.overlayRef.current.focus();

        window.addEventListener('popstate', this.hidePopUp);

        window.history.pushState(
            {
                popupOpen: true
            },
            '',
            `${location.pathname}${location.search}`
        );

        onVisible();
    }

    hidePopUpAndGoBack = () => {
        this.hidePopUp();
        history.goBack();
    }

    handleClickOutside = () => {
        const { clickOutside } = this.props;
        if (!clickOutside) {
            return;
        }
        this.hidePopUpAndGoBack()
    }

    handleKeyDown = (e) => {
        if (!this.hidePopUp()) {
            this.hidePopUp();
            return;
        }

        switch (e.keyCode) {
            case ESCAPE_KEY:
                this.hidePopUpAndGoBack();
                break;
            default:
                break;
            }
    }

    renderCloseButton() {
        const result = super.renderCloseButton();

        if (!result || !React.isValidElement(result)) {
            return result;
        }

        return React.cloneElement(result, { onClick: this.hidePopUpAndGoBack });
    }
}

export default Popup;
