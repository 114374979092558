import { createRef, PureComponent } from 'react';
import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';
import Snapengage from '../component/snapengage';

const HIDE_DISCOUNT_SIGN = 'hideDiscountSign';

const renderSnapengageButton = (member, context) => {
    return [
        ...member,
        {
            component: <Snapengage />,
            position: 40
        }
    ]
}

// const addClass = (className) => (args, callback, instance) => {
//     const { productOrVariant: { special_price_label } } = instance.props;
//     if (special_price_label != null) {
//         document.body.classList.add(className);
//     } else {
//         document.body.classList.remove(className);
//     }
//     return callback.apply(instance, args);
// }

// const removeClass = (className) => (args, callback, instance) => {
//     document.body.classList.remove(className);
//     return callback.apply(instance, args);
// }

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: {
                implementation: renderSnapengageButton,
                position: 1
            }
        }
    },
    // 'Route/ProductPage/Component': {
    //     'member-function': {
    //         'componentDidMount': addClass(HIDE_DISCOUNT_SIGN),
    //         'componentDidUpdate': addClass(HIDE_DISCOUNT_SIGN),
    //         'componentWillUnmount': removeClass(HIDE_DISCOUNT_SIGN)
    //     }
    // }
}