/* eslint-disable */

// imports the original component from vendor folder
import { Menu as HoeksMenu } from 'SourceComponent/Menu/Menu.component';
export * from 'SourceComponent/Menu/Menu.component';

// import new extended styles file
import './Menu.override.style.scss';

export class Menu extends HoeksMenu { 
    renderPromotionCms() {
        return (
            <>
            </>
        );
    }

    renderSubMenuDesktopItems = (item) => {
        const { item_id, children, item_class } = item;

        if (!Object.keys(children).length) {
            return null;
        }

        const { activeMenuItemsStack, closeMenu } = this.props;
        const isVisible = activeMenuItemsStack.includes(item_id);

        if (!isVisible) {
            return null;
        }

        return (
            <div
              block="Menu"
              elem="SubCategoriesWrapper"
              mods={ { isVisible } }
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="SubCategoriesWrapperInner"
                  mods={ { isVisible } }
                >
                    <div
                      block="Menu"
                      elem="SubCategories"
                      className={ item_class }
                    >
                        { this.renderSubLevel(item) }
                    </div>
                    { this.renderAdditionalInformation() }
                </div>
                <div
                  block="Menu"
                  elem="Overlay"
                  mods={ { isVisible } }
                  onMouseEnter={ closeMenu }
                />
            </div>
        );
    };
}

export default Menu;
