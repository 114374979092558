/* eslint-disable */
import { ClerkProductListContainer as SourceClerkProductListContainer, mapStateToProps, mapDispatchToProps } from '@hoeks/clerk/src/component/ClerkProductList/ClerkProductList.container';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { executeGet } from 'Util/Request';
import { prepareQuery } from 'Util/Query';
import { withRouter } from 'react-router-dom';

import ProductList from 'Component/ProductList';
import { updateLoadStatus, appendPage, updateProductListItems } from 'Store/ProductList/ProductList.action';
import { FilterInputType } from 'Type/ProductList';
import { getIndexedClerkProduct, prepareRequest, processItems } from '@hoeks/clerk/src/util/Clerk';
import { callApi } from '@hoeks/clerk/src/util/Clerk/Api';

/** @namespace Hoeks/Clerk/Component/ClerkProductList/Container */
export class ClerkProductListContainer extends SourceClerkProductListContainer {
    requestProductList(options) {
        const { args, args: { currentPage, pageSize, search }, isNext } = options;
        window.lastSearch = search;

        this.increment++;
        callApi("search/search",
            {
                limit: pageSize,
                attributes: [
                    'id', 
                    'name', 
                    'price', 
                    'image', 
                    'url', 
                    'sku', 
                    'list_price', 
                    'on_sale', 
                    'saerligt_god_pris', 
                    'special_from_date', 
                    'special_to_date',
                    'badge_limited_edition',
                    'badge_new',
                    'variant_badge',
                    'product_type'
                ],
                query: search,
                offset: (currentPage - 1) * pageSize,
                template: 'search-page'
            },
            (response) => this.handleResponse(response, args, isNext),
            function (response) {
                console.error(response);
            }
        );

    }

    handleResponse = (response, args, isNext) => {
        const { updateProductListItems, appendPage, defaultCurrency } = this.props;
        const { currentPage, pageSize } = args;
        const { product_data, count } = response;
        const skus = product_data.map(item => item.sku);
        const indexedItems = response.product_data.map((item) => {
            const indexItem = getIndexedClerkProduct(item, defaultCurrency);
            indexItem.saerligt_god_pris = item.saerligt_god_pris;
            indexItem.special_from_date = item.special_from_date;
            indexItem.special_to_date = item.special_to_date;
            indexItem.badge_limited_edition = item.badge_limited_edition;
            indexItem.badge_new = item.badge_new;
            indexItem.variant_badge = item.variant_badge;
            indexItem.maximum_price = indexItem.minimum_price;
            indexItem.type_id = item.product_type;
            return indexItem;
        });
        if (isNext) {
            console.log('append', currentPage, args);
            appendPage(
                processItems(indexedItems, skus, true),
                currentPage
            );
        }
        else {
            console.log('update', currentPage, args);
            updateProductListItems(
                processItems(indexedItems, skus, true),
                currentPage,
                count,
                Math.ceil(count / pageSize),
                args
            );
        }
        const incremet = this.increment;

        return;
        executeGet(prepareQuery(prepareRequest(skus)), 'ClerkProducts', 86400)
            .then((result) => this.onSuccess(result, skus, currentPage, indexedItems, incremet))
            .catch(
                (e) => console.log('error', 'Error fetching NewProducts!', e)
            );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClerkProductListContainer));
