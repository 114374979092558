/* eslint-disable */
import {
    SWITCH_ITEMS_TYPE
} from 'Component/Router/Router.config';
import FormPortal from 'Component/FormPortal';

const disabledComponentPositions = [60, 61, 62, 63, 65, 70, 71, 81];

const routerComponentMemberProperties = {
    [SWITCH_ITEMS_TYPE]: (member, instance) => {
        return [
            ...member.filter(({ position }) => !disabledComponentPositions.includes(position))
        ];
    }
}

const headerComponentMemberProperties = {
    renderMap: (member, instance) => {
        return {
            ...member,
            account: () => null
        }
    }
}

const headerComponentMemberFunctions = {
    renderAccountButton: (args, callback, instance) => null
}

const checkoutRouteContainerMemberFunctions = {
    onCreateUserChange: (args, callback, instance) => {}
}

const checkoutGuestFormComponentMemberFunctions = {
    renderGuestForm: (args, callback, instance) => {
        const { formId } = instance.props;
        return (
            <>
                { instance.renderHeading() }
                <FormPortal
                  id={ formId }
                  name="CheckoutGuestForm"
                >
                    { instance.renderFields() }
                </FormPortal>
            </>
        );
    }
}

const checkoutSuccessComponentMemberFunctions = {
    renderCreateAccountButton: (args, callback, instance) => null
}

const navigationTabsComponentMemberFunctions = {
    renderAccountButton: (args, callback, instance) => null
}

export default {
    'Component/Router/Component': {
        'member-property': routerComponentMemberProperties
    },
    'Component/Header/Component': {
        'member-property': headerComponentMemberProperties,
        'member-function': headerComponentMemberFunctions
    },
    'Route/Checkout/Component': {
        'member-function': checkoutRouteContainerMemberFunctions
    },
    'Component/CheckoutGuestForm/Component': {
        'member-function': checkoutGuestFormComponentMemberFunctions
    },
    'Component/CheckoutSuccess/Component': {
        'member-function': checkoutSuccessComponentMemberFunctions
    },
    'Component/NavigationTabs/Component': {
        'member-function': navigationTabsComponentMemberFunctions
    }
}