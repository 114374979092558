/* eslint-disable */

// imports the original component from vendor folder
import Sourcesnapengage from '@hoeks/snapengage/src/component/snapengage/snapengage.component';

// import new extended styles file
import './snapengage.override.style.scss';

export class snapengage extends Sourcesnapengage { 

}

export default snapengage; 
