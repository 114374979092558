/**
 * Hoeks.dk
 */
import Event, {
    EVENT_GTM_PRODUCT_DETAIL
} from '../../../util/Event';

import BaseEvent from './BaseEvent.event';
import ProductHelper from '../utils/Product3';

export const EVENT_HANDLE_DELAY = 700;
export const SPAM_PROTECTION_TIMEOUT = 2000;

/**
 * Website places, from where was received event data
 *
 * @type {string}
 */
export const LIST_ID_PRODUCT = 'product';

export const LIST_NAME_MAP = {
    [LIST_ID_PRODUCT]: 'Product Page'
}

class ViewItem3Event extends BaseEvent {
    /**
     * Set base event call delay
     *
     * @type {number}
     */
    eventHandleDelay = EVENT_HANDLE_DELAY;

    /**
     * Bind on product detail
     */
    bindEvent() {
        // Product
        Event.observer(EVENT_GTM_PRODUCT_DETAIL, ({ product, pathname, list }) => {
            this.handle(LIST_ID_PRODUCT, product, pathname, list);
        });
    }

    /**
     * Handle product detail event
     *
     * @param product
     * @param pathname
     */
    handler(productCollectionType = PLP_IMPRESSIONS, product, pathname, list) {
        const items = [
            {
                ...ProductHelper.getProductData(product),
                list: LIST_NAME_MAP[productCollectionType],
            }
        ];
        this.clearEcommerce();
        this.pushEventData({
            ecommerce: {
                currencyCode: ProductHelper.getCurrency(product),
                impressions: items
            }
        });
    }
}

export default ViewItem3Event;
