/* eslint-disable */
import VariantProductList from './VariantProductList.component';
import { PAGE_SIZE } from './VariantProductList.config';
import { connect } from 'react-redux';

/** @namespace Component/VariantProductList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Component/VariantProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({});

/** @namespace Component/VariantProductList/Container */
export class VariantProductListContainer extends PureComponent {
    state = {
        hasMore: true,
        length: PAGE_SIZE
    }

    __construct(props) {
        super.__construct(props);

        const {
            variants = []
        } = props;

        if (variants.length < PAGE_SIZE) {
            this.state = {
                ...this.state,
                hasMore: false,
                length: variants.length
            };
        }
    }

    containerFunctions = {
        getNext: this.getNext.bind(this)
    }

    containerProps = () => ({
        variants: this.getVariants(),
        variantQty: this.getVariantQty()
    })

    getNext() {
        const { variants = [] } = this.props;

        this.setState(state => {
            const addedLength = state.length + PAGE_SIZE;
            const hasMore = addedLength < variants.length;
            const length = hasMore ? addedLength : variants.length;

            return {
                ...state,
                hasMore,
                length
            };
        });
    }

    getVariants() {
        const { variants = [] } = this.props;
        const { length } = this.state;

        return variants.slice(0, length);
    }

    getVariantQty() {
        const { variants = [] } = this.props;

        return variants.length;
    }

    render() {
        return (
            <VariantProductList
                { ...this.props }
                { ...this.state }
                { ...this.containerFunctions }
                { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VariantProductListContainer);