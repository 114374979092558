/* eslint-disable */
import HelloRetailSearch from '../component/HelloRetailSearch';

const aroundRenderSearchField = (args, callback, instance) => {
    return <HelloRetailSearch key="clerksearch"/>;
}
const aroundRenderSearchFieldOverlay = (args, callback, instance) => {
    const { activeMenuItemsStack } = instance.props;
    const isVisible = !activeMenuItemsStack.length;
    return <HelloRetailSearch isVisible={isVisible} overlay={true} />;
}

export default {
    'Component/Header/Component': {
        'member-function': {
            'renderSearchField': aroundRenderSearchField
        }
    },
    'Component/MenuOverlay/Component': {
        'member-function': {
            'renderSearchField': aroundRenderSearchFieldOverlay
        }
    }
}