/* eslint-disable */
import { AddToCart as HoeksAddToCart } from 'HoeksComponent/AddToCart/AddToCart.component';
export * from 'HoeksComponent/AddToCart/AddToCart.component';

export class AddToCart extends HoeksAddToCart {
    render() {
        const {
            mix,
            product: { type_id },
            isLoading,
            buttonClick
        } = this.props;

        if (!type_id) {
            return this.renderPlaceholder();
        }

        return (
            <button
              onClick={ buttonClick }
              block="Button AddToCart"
              mix={ mix }
              mods={ { isLoading } }
              disabled={ isLoading }
            >
                <span>{ __('Add to cart') }</span>
                <span>{ __('Adding...') }</span>
            </button>
        );
    }
}

export default AddToCart;