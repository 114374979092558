/* eslint-disable */
import VariantProductCard from '../VariantProductCard';
import Loader from 'Component/Loader';
import { DEFAULT_SCROLLABLE_ID } from './VariantProductList.config';
import InfiniteScroll from 'react-infinite-scroll-component';

import './VariantProductList.style';

/** @namespace Component/VariantProductList/Component */
export class VariantProductList extends PureComponent {
    renderVariantProductCard = this.renderVariantProductCard.bind(this);

    getInfiniteScrollStyle() {
        const { isMobile } = this.props;

        const defaultStyles = {
            display: 'grid',
            gridAutoRows: 'max-content',
            gridColumnGap: '1.2rem',
            gridRowGap: '1.2rem',
            marginBottom: '1.2rem',
            gridColumn: '2',
            marginTop: '0',
        };

        if (isMobile) {
            return {
                ...defaultStyles,
                gridTemplateColumns: 'repeat(2, 1fr)',
                overflowX: 'hidden'
            };
        }

        return {
            ...defaultStyles,
            gridTemplateColumns: 'repeat(4, 1fr)'
        };
    }

    renderVariantProductCard(variant) {
        const { id } = variant;

        const {
            configurable_options,
            onVariantSelect,
            updateConfigurableVariantFull
        } = this.props;

        return (
            <VariantProductCard
                key={ id }
                product={ variant }
                configurable_options={ configurable_options }
                onVariantSelect={ onVariantSelect }
                updateConfigurableVariantFull={ updateConfigurableVariantFull }
                mix={ { block: 'VariantProductCard', elem: 'ProductCard' } }
            />
        );
    }

    renderEmptyVariantProductList() {
        return (
            <div
                block="VariantProductList"
                elem="Empty"
            >
                <p>{ __('Whoops - there are no variations available for your chosen combination, try to adjust it!') }</p>
                <p>{ __('Could not find what you are looking for?') } { __('Send us a message in the chat') }</p>
            </div>
        );
    }

    renderVariantProductList() {
        const {
            variants = [],
            getNext,
            hasMore,
            mobileInfiniteScrollId,
            desktopInfiniteScrollId,
            isMobile
        } = this.props;

        if (variants.length < 1) {
            return this.renderEmptyVariantProductList();
        }

        const scrollableTarget =
            isMobile
            ? (mobileInfiniteScrollId ? mobileInfiniteScrollId : DEFAULT_SCROLLABLE_ID)
            : (desktopInfiniteScrollId ? desktopInfiniteScrollId : DEFAULT_SCROLLABLE_ID);

        return (
            <InfiniteScroll
                dataLength={ variants.length }
                next={ getNext }
                hasMore={ hasMore }
                loader={ <Loader isLoading={ true }/> }
                endMessage={ '' }
                scrollableTarget={ scrollableTarget }
                style={ this.getInfiniteScrollStyle() }
            >
                { variants.map(this.renderVariantProductCard) }
            </InfiniteScroll>
        );
    }

    renderVariantQty() {
        const { variantQty } = this.props;

        const label = variantQty === 1 ? __('%s variant was found based on your choices', variantQty) : __('%s variants were found based on your choices', variantQty);

        return (
            <div
                block="VariantProductList"
                elem="Qty"
            >
                { label }
            </div>
        );
    }

    renderWrapper(children) {
        const {
            variants = [],
            isLoading
        } = this.props;

        const hasNoVariants = (variants.length < 1) ? true : false; 

        return (
            <ul
                block="VariantProductList"
                elem="Wrapper"
                id={ DEFAULT_SCROLLABLE_ID }
                mods={ { noProducts: hasNoVariants, isLoading } }
            >
                { children }
            </ul>
        );
    }

    renderLoader() {
        return (
            <Loader isLoading={ true }/>
        );
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return this.renderWrapper(
                this.renderLoader()
            );
        }

        return this.renderWrapper(
            <>
                { this.renderVariantQty() }
                { this.renderVariantProductList() }
            </>
        );
    }
}

export default VariantProductList;