/* eslint-disable */

// imports the original component from vendor folder
import SourceClerk from '@hoeks/clerk/src/component/Clerk/Clerk.component';

// import new extended styles file
import './Clerk.override.style.scss';

export class Clerk extends SourceClerk { 

    _sliderSettings(){
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 5,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 1919,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                }
            ]
        };

        return settings;
    }

}

export default Clerk; 
