/* eslint-disable */
import {
    convertQueryStringToKeyValuePairs
} from 'Util/Url';
import { CONFIGURABLE } from 'Util/Product';
import { updateQueryParamsWithoutHistory } from '../util/Url';
import ProductActions from 'Component/ProductActions';

const variantSkuFilter = (originalSKU) => ({ sku: variantSku }) => variantSku === originalSKU;

const getDerivedStateFromProps = (args, callback) => {
    const [ props, state ] = args;
    const result = callback.apply(null, args);

    const {
        product: {
            sku,
            variants,
            configurable_options
        },
        originalSKU,
        location: {
            search
        }
    } = props;

    const {
        currentProductSKU: prevSKU
    } = state;

    if (
        configurable_options &&
        sku !== originalSKU &&
        variants.some(variantSkuFilter(originalSKU))
    ) {
        const queryConfigurableAttributes = Object.entries(convertQueryStringToKeyValuePairs(search)).filter(([key]) => key in configurable_options);
        const configurableVariantIndex = variants.findIndex(variantSkuFilter(originalSKU));
        const {
            attributes: variantAttributes
        } = variants[configurableVariantIndex];

        const parameters = Object.entries(variantAttributes).reduce((acc, [key, value]) => {
            const {
                attribute_value
            } = value;
            if (key in configurable_options) {
                return { ...acc, [key]: attribute_value };
            }
            return acc;
        }, {});

        if (queryConfigurableAttributes.length <= 0) {
            return {
                ...result,
                parameters,
                configurableVariantIndex
            }
        } else {
            const mergedParameters = queryConfigurableAttributes.reduce((acc, [key, value]) => {
                return { ...acc, [key]: value };
            }, parameters);
            const matchingConfigurableVariantIndex = variants.findIndex(variant => {
                const {
                    attributes
                } = variant;
                const attributesArr = Object.entries(attributes);
                const mergedParametersArr = Object.entries(mergedParameters);
                return mergedParametersArr.every(([parameterKey, parameterValue]) => attributesArr.some(([attributeKey, { attribute_value: attributeValue }]) => parameterKey === attributeKey && parameterValue === attributeValue));
            });

            if (matchingConfigurableVariantIndex < 0) {
                return result;
            }

            return {
                ...result,
                parameters: mergedParameters,
                configurableVariantIndex: matchingConfigurableVariantIndex
            }
        }
    }

    const currentProductSKU = prevSKU === sku ? '' : prevSKU;

    /**
     * If the product we expect to load is loaded -
     * reset expected SKU
     */
    if (!configurable_options && !variants) {
        return {
            currentProductSKU
        };
    }

    const parameters = Object.entries(convertQueryStringToKeyValuePairs(search))
        .reduce((acc, [key, value]) => {
            if (key in configurable_options) {
                return { ...acc, [key]: value };
            }

            return acc;
        }, {});

    if (Object.keys(parameters).length !== Object.keys(configurable_options).length) {
        return {
            parameters,
            currentProductSKU,
            configurableVariantIndex: -1
        };
    }

    return result;
}

const updateBreadcrumbs = (args, callback, instance) => {
    const { updateBreadcrumbs, location } = instance.props;
    const { state: { prevCategoryId = null } = {} } = location;
    const {
        type_id,
        variants,
        categories
    } = instance.getDataSource();

    const {
        configurableVariantIndex
    } = instance.state;

    if (type_id === CONFIGURABLE && configurableVariantIndex >= 0 && (!categories || !categories.length)) {
        updateBreadcrumbs(variants[configurableVariantIndex], prevCategoryId);
        return;
    }

    return callback.apply(instance, args);
}

const componentDidUpdate = (args, callback, instance) => {
    const [_, prevState] = args;

    const {
        configurableVariantIndex
    } = instance.state;

    const {
        configurableVariantIndex: prevConfigurableVariantIndex
    } = prevState;

    if ((configurableVariantIndex !== prevConfigurableVariantIndex) && configurableVariantIndex >= 0) {
        instance.updateBreadcrumbs();
    }

    return callback.apply(instance, args);
}

const getConfigurableVariantIndex = (args, callback, instance) => {
    const { configurableVariantIndex } = instance.state;
    return configurableVariantIndex;
}

const updateConfigurableVariantFull = (args, callback, instance) => {
    const [ parameters ] = args;
    const { location, history } = instance.props;

    instance.setState(state => ({ ...state, parameters }));

    updateQueryParamsWithoutHistory(Object.entries(parameters), history, location);
    instance.updateConfigurableVariantIndex(parameters);
}

const containerFunctions = (originalMember, instance) => ({
    ...originalMember,
    updateConfigurableVariantFull: instance.updateConfigurableVariantFull.bind(instance)
})

const renderProductPageContent = (args, callback, instance) => {
    const result = callback.apply(instance, args);
    const { updateConfigurableVariantFull } = instance.props;

    if (!result || !React.isValidElement(result)) {
        return result;
    }

    if (result.props.children && React.Children.count(result.props.children)) {
        const updatedChildren = React.Children.map(result.props.children, (child) => {
            if (React.isValidElement(child) && child.type === ProductActions) {
                return React.cloneElement(child, { updateConfigurableVariantFull })
            }

            if (React.isValidElement(child) && React.Children.count(child.props.children)) {
                const updatedGrandChildren = React.Children.map(child.props.children, (grandChild) => {
                    if (React.isValidElement(grandChild) && grandChild.type === ProductActions) {
                        return React.cloneElement(grandChild, { updateConfigurableVariantFull });
                    }
                    return grandChild;
                })
                return React.cloneElement(child, {}, ...updatedGrandChildren);
            }

            return child;
        })

        const updatedResult = React.cloneElement(result, {}, ...updatedChildren);

        return updatedResult;
    }

    return result;
}

export default {
    'Route/ProductPage/Container': {
        'static-member': {
            getDerivedStateFromProps
        },
        'member-function': {
            updateBreadcrumbs,
            componentDidUpdate,
            getConfigurableVariantIndex,
            updateConfigurableVariantFull
        },
        'member-property': {
            containerFunctions
        }
    },
    'Route/ProductPage/Component': {
        'member-function': {
            renderProductPageContent
        }
    }
}