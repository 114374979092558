// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CategoryBlock from './CategoryBlock.component';

/** @namespace Hoeks/Categoryblock/Component/CategoryBlock/Container/CategoryBlockContainer */
export class CategoryBlockContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    state = {
        subCategories: [],
        groups: []
    }

    componentDidMount() {
        const { subcategories: subcategoriesJson, groups: groupsJson} = this.props;
        const subCategories = JSON.parse(subcategoriesJson);
        const groups = JSON.parse(groupsJson);
        this.setState({subCategories: subCategories, groups: groups});
    }

    containerFunctions = {
        menuItemOnClick: this.menuItemOnClick.bind(this)
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    menuItemOnClick(index) {
        const target = index != 0 ? index - 1 : index;
        const targetElement = document.getElementById(`category-group-${target}`);
        targetElement.scrollIntoView();
    }

    render() {
        return (
            <CategoryBlock
                {...this.state}
                { ...this.containerFunctions }
                { ...this.containerProps() }
            />
        );
    }
}

export default CategoryBlockContainer;
