// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './SparxpresWidget.style';

/** @namespace Hoeks/Sparxpres/Component/SparxpresWidget/Component/SparxpresWidgetComponent */
export class SparxpresWidgetComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    componentDidMount() {
        window.dispatchEvent(new Event("sparxpresRender"));
    }

    renderContent() {
        
    }

    render() {
        return (
            <div block="SparxpresWidget">
                <div id="SPX_Finance"></div>
                { /* TODO: Implement render method */ }
            </div>
        );
    }
}

export default SparxpresWidgetComponent;
