/* eslint-disable */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ClerkSearch from '@hoeks/clerk/src/component/ClerkSearch/ClerkSearch.component';
import { CLERK_SEARCH_ENABLED } from '@hoeks/clerk/src/component/Clerk/Clerk.config';
import { getIndexedClerkProduct, prepareRequest, processItems, processLinkedData } from '@hoeks/clerk/src/util/Clerk';
import { debounce } from 'Util/Request';
import { executeGet } from 'Util/Request';
import { prepareQuery } from 'Util/Query';
import { callApi } from '@hoeks/clerk/src/util/Clerk/Api';

import history from 'Util/History';

import './ClerkSearch.override.style.scss';

/** @namespace Hoeks/Clerk/Component/ClerkSearch/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    enabled: state.ConfigReducer[CLERK_SEARCH_ENABLED],
    device: state.ConfigReducer.device,
    searchTemplate: state.ConfigReducer.clerk_livesearch_template,
    suggestionsLimit: state.ConfigReducer.clerk_livesearch_suggestions,
    categoriesLimit: state.ConfigReducer.clerk_livesearch_categories,
    defaultCurrency: state.ConfigReducer.default_display_currency_code
});

/** @namespace Hoeks/Clerk/Component/ClerkSearch/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
});

/** @namespace Hoeks/Clerk/Component/ClerkSearch/Container/ClerkSearchContainer */
export class ClerkSearchContainer extends PureComponent {
    static propTypes = {
    };
    static defaultProps = {
        isVisible: true
    }
    state = {
        searchCriteria: '',
        count: 0,
        suggestions: [],
        categories: []
    }

    __construct(props){
        super.__construct(props);
        const { location: { pathname = '' } = {} } = history;
        const parts = pathname.split('/');
        if (parts.length >= 2 && parts.length <= 4){
            const [search, criteria] = parts.slice(-2);
            if (search === 'search' && criteria){
                this.state = {
                    searchCriteria: criteria
                }
            }
        }
    }

    containerFunctions = {
        onSearchBarChange: this.onSearchBarChange.bind(this),
        closeResults: this.closeResults.bind(this),
        onFocus: this.onFocus.bind(this)
    };

    componentDidMount() {
        this.debouncedSearch = debounce(this.handleSearch, 500);
    }

    increment = 0;

    async fetchData(){
        const { searchCriteria } = this.state;
        const { searchTemplate } = this.props;
        this.increment++;
        const payload = {
            limit: 5,
            attributes: ['id','name','price','image','url','sku', 'list_price', 'on_sale', 'purchase_by_contact', 'product_type'],
            query: searchCriteria
        };
        if (searchTemplate){
            payload['template'] = searchTemplate;
        }
        const call = searchTemplate ? '' : 'search/search';

        callApi(call,
            payload,
            this.handleResponse,
            function(response){ 
              console.error(response); 
            }
        );
    }

    async fetchSuggestions(){
        const { suggestionsLimit } = this.props;
        const { searchCriteria } = this.state;
        callApi("search/suggestions",
            {
              limit: suggestionsLimit,
              query: searchCriteria
            },
            (result) => { this.setState({suggestions: result.result})},
            function(response){ 
              console.error(response); 
            }
        );
    }

    async fetchCategories(){
        const { categoriesLimit } = this.props;
        const { searchCriteria } = this.state;
        console.log(categoriesLimit);
        callApi("search/categories",
            {
              limit: categoriesLimit,
              query: searchCriteria
            },
            (result) => { this.setState({categories: result.categories})},
            function(response){ 
              console.error(response); 
            }
        );
    }

    handleSearch = () => {
        this.fetchData();
        this.fetchSuggestions();
        this.fetchCategories();
    }

    handleResponse = (response) => {
        const { defaultCurrency } = this.props;

        const skus = response.product_data.map(item => item.sku);
        const indexedItems = response.product_data.map((item) => {
            const product = getIndexedClerkProduct(item, defaultCurrency);
            product.purchase_by_contact = item.purchase_by_contact;
            product.product_type = item.product_type;
            return product;
        });
        this.setState({result: processItems(indexedItems, skus, true), count: response.count, areDetailsLoaded: true});
        const increment = this.increment;

        return;
        executeGet(prepareQuery(prepareRequest(skus)), 'ClerkProducts', 86400)
        .then((result) => this.onSuccess(result, skus, indexedItems, increment))
        .catch(
            (e) => console.log('error', 'Error fetching NewProducts!', e)
        );
    }

    onSuccess(data, skus, indexedItems, increment) {
        if (this.increment !== increment){
            return;
        }
        const {
            products: {
                items = []
            } = {}
        } = data;
        const thumbnailItems = items.map(({ id, thumbnail }) => ({ id, thumbnail }));
        const processedItems = processLinkedData(data, skus, indexedItems);
        this.setState({ linkedProducts: processedItems, areDetailsLoaded: true, thumbnailItems });
        return processedItems;    
    }

    onFocus() {
        this.setState(state => ({
            ...state,
            isOpen: true
        }));
    }

    containerProps = () => {
        return {
            ...this.state,
            ...this.props
        }
    };

    onSearchBarChange(value){
        this.setState({
            searchCriteria: value, 
            isOpen: !!value, 
            areDetailsLoaded: false, 
            result: [], 
            suggestions: [], 
            categories: []
        });
        this.debouncedSearch();
    }

    closeResults(clear = false){
        // if (history.location ){

        // }
        // if (clear && history.location.pathname.indexOf('/search/') === -1){
        //     this.setState({isOpen: false});
        // }
        // else {
        this.setState(state => ({ isOpen: false, searchCriteria: clear ? '' : state.searchCriteria }));
        // }
    }

    render() {
        const {enabled} = this.props;
        if (!enabled) return null;
        return (
            <ClerkSearch
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClerkSearchContainer);
