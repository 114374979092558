/* eslint-disable */
import Field from 'Component/Field';
import './VariantFilterOption.style';

/** @namespace Component/VariantFilterOption/Component */
export class VariantFilterOption extends PureComponent {
    render() {
        const {
            option: {
                label,
                value
            },
            attribute: {
                attribute_code
            },
            onClick,
            isChecked,
            getQtyPreview
        } = this.props;

        const id = `${attribute_code}-${value}`;

        const finalLabel = isChecked ? label : `${label} (${getQtyPreview(attribute_code, value)})`;

        return (
            <Field
                id={ id }
                name={ id }
                type='checkbox'
                label={ finalLabel }
                mix={ { block: 'VariantFilterOption', elem: 'Text' } }
                checked={ isChecked }
                onChange={ onClick }
            />
        );
    }
}

export default VariantFilterOption;