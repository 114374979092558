/* eslint-disable */
import { VariantProductCard as SourceVariantProductCard } from '@hoeks/simple-configurable-product/src/component/VariantProductCard/VariantProductCard.component';
export * from '@hoeks/simple-configurable-product/src/component/VariantProductCard/VariantProductCard.component';

/** @namespace Component/VariantProductCard/Component */
export class VariantProductCard extends SourceVariantProductCard {
    render() {
        const result = super.render();

        if (!result || !React.isValidElement(result)) {
            return result;
        }

        if (React.isValidElement(result.props.children)) {
            return result.props.children;
        }

        return result;
    }
}

export default VariantProductCard;