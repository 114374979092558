/* eslint-disable */

/** @namespace Util/Product/productHasSpecialPrice */
export const productHasSpecialPrice = (product) => {
    const { price_range, special_from_date, special_to_date } = product;

    if (price_range != undefined) {
        const {
            maximum_price,
            minimum_price
        } = price_range;
        if (maximum_price != undefined) {
            const {
                discount: {
                    amount_off,
                    percent_off
                }
            } = maximum_price
            if (amount_off || percent_off) {
                return checkForSpecialPrice(special_from_date, special_to_date);
            }
        }

        if (maximum_price == undefined && minimum_price != undefined) {
            const {
                discount: {
                    amount_off,
                    percent_off
                }
            } = minimum_price
            if (amount_off || percent_off) {
                return checkForSpecialPrice(special_from_date, special_to_date);
            }
        }
    }
    return false;
}

/** @namespace Util/Product/checkForSpecialPrice */
export const checkForSpecialPrice = (special_from_date, special_to_date) => {
    const today = new Date;
    const fromDate = parseDate(special_from_date);
    const toDate = parseDate(special_to_date);
    if (toDate !== null && toDate) {
        toDate.setDate(toDate.getDate() + 1);
    }

    if (fromDate <= today && toDate >= today) {
        return true;
    }

    if (fromDate <= today && special_to_date == null) {
        return true;
    }

    if (special_from_date == null && toDate >= today) {
        return true;
    }

    if (special_from_date == null && special_to_date == null) {
        return true;
    }

    if (special_from_date == undefined && special_to_date == undefined) {
        return true;
    }

    return false;
}

/** @namespace Util/Product/parseDate */
export const parseDate = (date) => {
    if (date == null || date == undefined || date == "") return null
    const dateArr = date.split(/[^0-9]/);
    const parsedDate = new Date(dateArr[0], dateArr[1] - 1, dateArr[2], dateArr[3], dateArr[4], dateArr[5])
    return parsedDate;
}