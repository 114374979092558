/* eslint-disable */
import NewsletterForm from "../component/NewsletterForm";
import NewsletterFormPlacholder from "../component/NewsletterFormPlacholder";
export const NEWSLETTER_TYPE = 'HoeksNewsletter'
const addNewsletterWidget = (member, instance) => {
    return {
        ...member,
        [NEWSLETTER_TYPE]: {
            component: NewsletterForm,
            fallback: () => <NewsletterFormPlacholder />
        }
    }
}

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': {
                implementation: addNewsletterWidget,
                position: 0
            }
        }
    },
}