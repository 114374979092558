/* eslint-disable */
import { MenuItemContainer as SourceMenuItemContainer, mapStateToProps, mapDispatchToProps } from 'SourceComponent/MenuItem/MenuItem.container';
import { HOVER_TIMEOUT } from 'Component/MenuItem/MenuItem.config';
import { connect } from 'react-redux';

export class MenuItemContainer extends SourceMenuItemContainer {
    handleLinkLeave() {
        // console.log('debug leave');
        clearTimeout(this.menuHoverTimeout);
    }

    handleCategoryHover() {
        // console.log('debug enter');
        const { onCategoryHover, item, activeMenuItemsStack } = this.props;

        const hoverTimeOut = activeMenuItemsStack.length === 0 ? HOVER_TIMEOUT : 0;

        this.menuHoverTimeout = setTimeout(() => {
            onCategoryHover(item);
        }, hoverTimeOut);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemContainer);