/* eslint-disable */
import VariantCustomFilterOption from './VariantCustomFilterOption.component';

/** @namespace Component/VariantCustomFilterOption/Container */
export class VariantCustomFilterOptionContainer extends PureComponent {
    containerFunctions = {
        onClick: this.onClick.bind(this)
    }

    containerProps = () => ({
        isChecked: this.isChecked()
    })

    onClick() {
        const { customFilterKey, updateFilter } = this.props;

        updateFilter(customFilterKey);
    }

    isChecked() {
        const { customFilterKey, enabledCustomFilters } = this.props;

        const isChecked = enabledCustomFilters.includes(customFilterKey);

        return isChecked;
    }

    render() {
        return (
            <VariantCustomFilterOption
                { ...this.props }
                { ...this.containerFunctions }
                { ...this.containerProps() }
            />
        );
    }
}

export default VariantCustomFilterOptionContainer;