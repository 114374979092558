/* eslint-disable */
import { convertQueryStringToKeyValuePairs } from '@scandipwa/scandipwa/src/util/Url';
import { FieldSelectContainer as SourceFieldSelectContainer } from 'SourceComponent/FieldSelect/FieldSelect.container';

export class FieldSelectContainer extends SourceFieldSelectContainer {
    static defaultProps = {
        selectOptions: [],
        formRef: () => { },
        onChange: () => { },
        isConfigurableAttribute: false
    };

    state = {
        valueIndex: -1,
        searchString: 'a',
        isSelectExpanded: false,
        currentOption: {}
    };

    containerFunctions = {
        handleSelectExpand: this.handleSelectExpand.bind(this),
        handleSelectExpandedExpand: this.handleSelectExpandedExpand.bind(this),
        handleSelectListOptionClick: this.handleSelectListOptionClick.bind(this),
        handleSelectListKeyPress: this.handleSelectListKeyPress.bind(this),
        getOptionBadge: this.getOptionBadge.bind(this),
        getCurentOptionLabel: this.getCurentOptionLabel.bind(this)
    };

    componentDidMount() {
        const { isConfigurableAttribute, special_from_date, special_to_date, hasSpecialPrice } = this.props;
        if (!isConfigurableAttribute || this.isSingelConfigOption()) return;
        const option = this.getCurentOptionLabel(special_from_date, special_to_date, hasSpecialPrice);
        this.setState({ currentOption: option });
    }

    componentDidUpdate() {
        const { isConfigurableAttribute, special_from_date, special_to_date, hasSpecialPrice } = this.props;
        const { currentOption } = this.state;

        if (!isConfigurableAttribute || this.isSingelConfigOption()) return;

        const option = this.getCurentOptionLabel(special_from_date, special_to_date, hasSpecialPrice);

        if (option == undefined) return;
        if (currentOption == undefined) {
            this.setState({ currentOption: option });
            return;
        }

        const isCurrentOption = JSON.stringify(option) === JSON.stringify(currentOption);

        if (!isCurrentOption) {
            this.setState({ currentOption: option });
        }
    }

    getVariantsBadgesWithParameters() {
        const { attributeCode, parameters, variants } = this.props;
        const parameterKeys = this.getParameterKeys();
        const priceLabels = [];
        parameterKeys.forEach(parameterKey => {
            const parameterValue = parameters[parameterKey];

            variants.forEach(variant => {
                // console.log('debug - variant', JSON.parse(JSON.stringify(variant)), parameterKey);
                const attributeValue = variant.attributes[parameterKey]?.attribute_value;

                if ((parameterValue == attributeValue)) {
                    const { attributes } = variant;
                    const label = this.getBadgeLabel(variant);

                    const priceLabel = {
                        attribute: attributeCode,
                        option: this.getAllAttributeLabels(attributes),
                        label: label
                    }

                    if (label != '') priceLabels.push(priceLabel);
                }
            });
        });
        return priceLabels;
    }

    getVariantsBadgesWithoutParameters() {
        const { attributeCode, variants } = this.props;
        const priceLabels = [];

        variants.forEach(variant => {
            const { attributes } = variant;
            const label = this.getBadgeLabel(variant);

            const priceLabel = {
                attribute: attributeCode,
                option: this.getAllAttributeLabels(attributes),
                label: label
            }

            if (label != '') priceLabels.push(priceLabel);
        });

        return priceLabels;
    }

    getBadgeLabel(variant) {
        const {
            attributes,
            saerligt_god_pris,
            badge_new,
            badge_limited_edition,
            special_from_date,
            special_to_date,
            price_range: {
                maximum_price: {
                    discount: {
                        amount_off,
                        percent_off
                    }
                }
            }
        } = variant;

        const today = new Date();
        var label = '';
        if (badge_new)
            label = __('News');

        if (badge_limited_edition)
            label = __('Limited Edition');

        if (saerligt_god_pris)
            label = __('Blue price');

        if (amount_off || percent_off && this.parseDate(special_from_date) <= today && this.parseDate(special_to_date) >= today)
            label = __('Tilbud');

        if (amount_off || percent_off && this.parseDate(special_from_date) <= today && special_to_date == null)
            label = __('Tilbud');

        if (amount_off || percent_off && special_from_date == null && this.parseDate(special_to_date) >= today)
            label = __('Tilbud');

        if (amount_off || percent_off && special_from_date == null && special_to_date == null)
            label = __('Tilbud');

        return label;
    }

    getCurentOptionLabel(special_from_date, special_to_date, hasSpecialPrice) {
        const attributeLabels = {};
        const { parameters, attributes, variantAttributes } = this.props;
        const attributeCodes = ['badge_new', 'badge_limited_edition', 'saerligt_god_pris'];
        const parameterKeys = this.getParameterKeys();
        const today = new Date()

        attributeCodes.forEach(code => {
            if (variantAttributes[code] != undefined) {

                var badge = '';

                switch (code) {
                    case 'badge_new':
                        badge = __('News');
                        break;

                    case 'badge_limited_edition':
                        badge = __('Limited Edition');
                        break;

                    case 'saerligt_god_pris':
                        badge = __('Blue price')
                        break;

                    default:
                        break;
                }

                if (hasSpecialPrice && this.parseDate(special_from_date) <= today && this.parseDate(special_to_date) >= today)
                    badge = __('Tilbud');

                if (hasSpecialPrice && this.parseDate(special_from_date) <= today && special_to_date == null)
                    badge = __('Tilbud');
                
                if (hasSpecialPrice && special_from_date == null && this.parseDate(special_to_date) >= today)
                    badge = __('Tilbud');

                if (hasSpecialPrice && special_from_date == null && special_to_date == null)
                    badge = __('Tilbud');

                parameterKeys.forEach(key => {
                    const {
                        attribute_options = []
                    } = attributes[key];
                    
                    if (attribute_options[parameters[key]] == undefined) {
                        attributeLabels[key] = '';
                        attributeLabels['badge'] = badge;

                    } else {
                        const {
                            label = ''
                        } = attribute_options[parameters[key]];
                        attributeLabels[key] = label;
                        attributeLabels['badge'] = badge;
                    }
                });
            }
        });

        return attributeLabels;
    }

    parseDate(date) {
        if (date == null) return null
        const dateArr = date.split(/[^0-9]/);
        const parsedDate = new Date(dateArr[0], dateArr[1] - 1, dateArr[2], dateArr[3], dateArr[4], dateArr[5])
        return parsedDate;
    }

    getAllAttributeLabels(attributes) {
        const attributeLabels = [];
        Object.keys(attributes).forEach(key => {
            const { attribute_options, attribute_value } = attributes[key];
            if (attribute_options[attribute_value] != undefined) attributeLabels.push(attribute_options[attribute_value].label);
        })

        return attributeLabels;
    }

    getParameterKeys() {
        const { parameters } = this.props;
        const parameterKeys = [];
        Object.keys(parameters).forEach(key => {
            parameterKeys.push(key);
        });
        return parameterKeys;
    }

    isSingelConfigOption() {
        const { variants, product = {} } = this.props;
        const { configurable_options = {} } = product;
        var isListLengthOne = false;
        // const attributesToExclude = ["saerligt_god_pris", "badge_limited_edition", "badge_new", "brands", "designer", "leveringtid_ny", "variant_badge"];
        // console.log(Object.keys(configurable_options).length);
        // const attributeLength = Object.keys(product.attributes).filter(attributeKey => {
        //     // console.log(attributeKey);
        //     // console.log(attributesToExclude.includes(attributeKey));
        //     if (!attributesToExclude.includes(attributeKey)) {
        //         return attributeKey
        //     }
        // }).length;

        if (Object.keys(configurable_options).length == 1) {
            isListLengthOne = true;
        } else {
            isListLengthOne = false;
        }

        return isListLengthOne;
    }

    getOptionBadge(option) {
        const { isConfigurableAttribute, attributeCode } = this.props;
        const { currentOption } = this.state;
        if (!isConfigurableAttribute) return null;
        // console.log(this.isSingelConfigOption());
        const priceLabels = this.isSingelConfigOption() ? this.getVariantsBadgesWithoutParameters() : this.getVariantsBadgesWithParameters();
        var optionLabel = '';

        if (currentOption != undefined) {
            if (Object.values(currentOption).includes(option)) {
                return currentOption['badge']; 
            }
        }

        priceLabels.forEach(priceLabel => {
            if (priceLabel.option.includes(option)) {
                optionLabel = priceLabel.label;
            }
        });

        return optionLabel;
    }
}

export default FieldSelectContainer;