/* eslint-disable */
import AddToWishlistButton from './AddToWishlistButton.component';
import { connect } from 'react-redux';
import { showNotification } from 'Store/Notification/Notification.action';

export const LocalWishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/LocalWishlist/LocalWishlist.dispatcher'
);

/** @namespace Component/AddToWishlistButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    wishlist: state.LocalWishlistReducer.wishlist
})

/** @namespace Component/AddToWishlistButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    addToWishlist: (product) => LocalWishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addToWishlist({ product }, dispatch)
    ),
    removeFromWishlist: (product) => LocalWishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeFromWishlist({ product }, dispatch)
    )
})

/** @namespace Component/AddToWishlistButton/Container */
export class AddToWishlistButtonContainer extends PureComponent {
    containerFunctions = {
        onButtonClick: this.onButtonClick.bind(this)
    }

    containerProps = () => ({
        isInWishlist: this.isInWishlist()
    })

    isInWishlist() {
        const {
            productOrVariant: {
                id: productId
            },
            wishlist
        } = this.props;

        const isInWishlist = wishlist.some(({ id }) => id === productId);
        return isInWishlist;
    }

    onButtonClick() {
        if (!this.isInWishlist()) {
            this.addToWishlist();
        } else {
            this.removeFromWishlist();
        }
    }

    addToWishlist() {
        const {
            productOrVariant,
            addToWishlist,
            showNotification,
            wishlist
        } = this.props;

        if (wishlist && wishlist.length >= 40) {
            showNotification('error', __('Your wishlist is full, the product was not added.'));
            return;
        }

        addToWishlist(productOrVariant);
        showNotification('success', __('Product added to wishlist.'));
    }

    removeFromWishlist() {
        const {
            productOrVariant,
            removeFromWishlist,
            showNotification
        } = this.props;

        removeFromWishlist(productOrVariant);
        showNotification('success', __('Product removed from wishlist.'));
    }

    render() {
        return (
            <AddToWishlistButton
                { ...this.props }
                { ...this.containerFunctions }
                { ...this.containerProps() }
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToWishlistButtonContainer);
