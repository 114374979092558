export const LOCAL_WISHLIST_ADD_TO_WISHLIST = 'LOCAL_WISHLIST_ADD_TO_WISHLIST';
export const LOCAL_WISHLIST_REMOVE_FROM_WISHLIST = 'LOCAL_WISHLIST_REMOVE_FROM_WISHLIST';
export const LOCAL_WISHLIST_UPDATE_WISHLIST = 'LOCAL_WISHLIST_UPDATE_WISHLIST';

/** @namespace Store/LocalWishlist/Action/addToWishlist */
export const addToWishlist = (payload) => ({
    type: LOCAL_WISHLIST_ADD_TO_WISHLIST,
    payload
});

/** @namespace Store/LocalWishlist/Action/removeFromWishlist */
export const removeFromWishlist = (payload) => ({
    type: LOCAL_WISHLIST_REMOVE_FROM_WISHLIST,
    payload
});

/** @namespace Store/LocalWishlist/Action/updateWishlist */
export const updateWishlist = (payload) => ({
    type: LOCAL_WISHLIST_UPDATE_WISHLIST,
    payload
});