// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import NewsletterSubscriptionQuery from 'Query/NewsletterSubscription.query';
import { debounce, fetchMutation, fetchQuery } from 'Util/Request';
import { showNotification } from 'Store/Notification/Notification.action';

import NewsletterForm from './NewsletterForm.component';

export const NewsletterSubscriptionDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/NewsletterSubscription/NewsletterSubscription.dispatcher'
)

/** @namespace Hoeks/NewsletterPage/Component/NewsletterForm/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
});

/** @namespace Hoeks/NewsletterPage/Component/NewsletterForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    subscribeToNewsletter: (email, name, moebelhus) => NewsletterSubscriptionDispatcher.then(
        ({ default: dispatcher }) => dispatcher.subscribeToNewsletter(_dispatch, email, name, moebelhus)
    )
});

/** @namespace Hoeks/NewsletterPage/Component/NewsletterForm/Container/NewsletterFormContainer */
export class NewsletterFormContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    state = {
        moebelhus: [],
        subscriberName: '',
        email: ''
    }

    containerFunctions = {
        onCheckboxChange: this.onCheckboxChange.bind(this),
        onNameFieldChange: this.onNameFieldChange.bind(this),
        onEmailFieldChange: this.onEmailFieldChange.bind(this),
        onBtnClick: this.onBtnClick.bind(this)
    };

    onCheckboxChange(value, checked) {
        if (checked) {
            this.setState(state => ({
                ...state,
                moebelhus: !state.moebelhus.includes(value) ? [...state.moebelhus, value] : state.moebelhus
            }));
        } else {
            this.setState(state => ({
                ...state,
                moebelhus: state.moebelhus.includes(value) ? state.moebelhus.filter(hus => hus != value) : state.moebelhus
            }));
        }
    }

    onNameFieldChange(value) {
        this.setState({subscriberName: value});
    }

    onEmailFieldChange(value) {
        this.setState({email: value});
    }

    onBtnClick() {
        const { email, subscriberName, moebelhus } = this.state;
        const { subscribeToNewsletter } = this.props;
        const moebelhusStr = moebelhus.toString();

        subscribeToNewsletter(email, subscriberName, moebelhusStr).then(result => {
            if (result == 'success') {
                const emailElement = document.getElementById('email');
                const nameElement = document.getElementById('name');
                const warehousesElement = document.getElementById('newsletter_warehouses');
                const submitbtnElement = document.getElementById('submit-btn');
                emailElement.classList.remove("error");
                nameElement.classList.remove("error");
                warehousesElement.classList.remove("error");
                submitbtnElement.value = "Tak for din tilmelding";
                this.clearResult();
            } else {
                if (result.msgType == 'error') {
                    this.isValidtInput(email, subscriberName, moebelhusStr);
                }
            }
        });
    }

    clearResult() {
        const emailElement = document.getElementById('email');
        const nameElement = document.getElementById('name');
        const checkboxElements = document.getElementsByClassName('checkbox');
        for (let i = 0; i < checkboxElements.length; i++) {
            const element = checkboxElements[i];
            element.checked = false;
        }
        emailElement.value = '';
        nameElement.value = '';

        this.setState({moebelhus: [], subscriberName: '', email: ''});
    }

    isValidtInput(email, name, moebelhus) {
        const emailElement = document.getElementById('email');
        const nameElement = document.getElementById('name');
        const warehousesElement = document.getElementById('newsletter_warehouses');

        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (email == '' || !re.test(email)) {
            emailElement.classList.add("error");
        } else {
            emailElement.classList.remove("error");
        }

        if (name == '') {
            nameElement.classList.add("error");
        } else {
            nameElement.classList.remove("error");
        }

        if (moebelhus == '') {
            warehousesElement.classList.add("error");
        } else {
            warehousesElement.classList.remove("error");
        }
    }

    render() {
        return (
            <NewsletterForm
                {...this.state}
                {...this.props}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterFormContainer);
