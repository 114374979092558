/* eslint-disable */
import { connect } from 'react-redux';
import { ProductCardContainer as HoeksProductCardContainer, mapStateToProps, mapDispatchToProps } from 'HoeksComponent/ProductCard/ProductCard.container';
export * from 'HoeksComponent/ProductCard/ProductCard.container';

export class ProductCardContainer extends HoeksProductCardContainer {
    containerFunctions = {
        ...this.containerFunctions,
        setScrollProductId: this.setScrollProductId.bind(this)
    }

    setScrollProductId() {
        const {
            product: {
                id
            } = {}
        } = this.props;

        window.scrollProductId = id;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);