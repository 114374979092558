/* eslint-disable */

import CmsBlock from 'Component/CmsBlock';

import { Footer as HoeksFooter } from 'HoeksComponent/Footer/Footer.component';

import { COLUMN_MAP, NEWSLETTER_COLUMN, RENDER_NEWSLETTER } from './Footer.config';

import './Footer.override.style.scss';

export class Footer extends HoeksFooter {
    render() {
        const { isVisibleOnMobile, device } = this.props;
    
        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }
    
        if (isVisibleOnMobile && !device.isMobile) {
            return null;
        }
    
        return (
            <>
            <footer block="Footer" aria-label="Footer">
                <CmsBlock identifier={ "footer-feedback-block" } />
                <CmsBlock identifier={ "footer-stores" } />
                <CmsBlock identifier={ "footer-bottom" } />
            </footer>
            </>
        );
    }
}

export default Footer;
