// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import TextPlaceholder from 'Component/TextPlaceholder';
import './SparxpresWidgetPlaceholder.style';

/** @namespace Hoeks/Sparxpres/Component/SparxpresWidgetPlaceholder/Component/SparxpresWidgetPlaceholderComponent */
export class SparxpresWidgetPlaceholderComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        return (
            <div block="SparxpresWidgetPlaceholder">
                <TextPlaceholder></TextPlaceholder>
            </div>
        );
    }
}

export default SparxpresWidgetPlaceholderComponent;
