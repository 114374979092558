/* eslint-disable */
import { lazy } from 'react';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import { EXPANDABLE_CONTENT } from '../component/HtmlExpandableContent/HtmlExpandableContent.config';
export const HtmlExpandableContent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ '../component/HtmlExpandableContent'));

const htmlComponentMemberProperties = {
    rules: (member, instance) => {
        return [
            ...member,
            {
                query: { name: ['div'] },
                replace: instance.replaceDiv
            }
        ]
    },
    renderMap: (member, instance) => {
        return {
            ...member,
            [EXPANDABLE_CONTENT]: {
                component: HtmlExpandableContent
            }
        }
    }
}

const htmlComponentMemberFunctions = {
    replaceDiv: (args, callback, instance) => {
        const [ { attribs, children } ] = args;

        const {
            class: className = ''
        } = attribs;
        const replaceClass = className.split(' ').find(someClass => instance.renderMap[someClass] !== undefined);
        if (replaceClass) {
            const {
                component: ReplaceComponent
            } = instance.renderMap[replaceClass];
            return <ReplaceComponent { ...attributesToProps(attribs) } children={ domToReact(children, instance.parserOptions) } />
        }

        return <div { ...attributesToProps(attribs) } >{ domToReact(children, instance.parserOptions) }</div>;
    }
}

export default {
    'Component/Html/Component': {
        'member-function': htmlComponentMemberFunctions,
        'member-property': htmlComponentMemberProperties
    }
}