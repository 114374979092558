/* eslint-disable */
import ProductConfigurableAttributesDetails from '../component/ProductConfigurableAttributesDetails';
import ProductConfigurableAttributesPopup from '../component/ProductConfigurableAttributesPopup';

const renderPopup = (args, callback, instance) => {
    const {
        updateConfigurableVariantFull,
        parameters,
        areDetailsLoaded,
        product: { configurable_options, variants },
        product,
        productOrVariant,
        areVariantsLoaded
    } = instance.props;

    return (
        <div
            ref={ instance.configurableOptionsRef }
            block="ProductActions"
            elem="AttributesWrapper"
        >
            <ProductConfigurableAttributesPopup
                parameters={ parameters }
                updateConfigurableVariantFull={ updateConfigurableVariantFull }
                configurable_options={ configurable_options }
                variants={ variants }
                areDetailsLoaded={ areDetailsLoaded }
                product={ product }
                productOrVariant={ productOrVariant }
                areVariantsLoaded={ areVariantsLoaded }
            />
        </div>
    );
}

const renderDetails = (args, callback, instance) => {
    const {
        parameters,
        areDetailsLoaded,
        product: { configurable_options },
        product
    } = instance.props;

    return (
        <div
            block="ProductActions"
            elem="AttributesValuesWrapper"
        >
            <ProductConfigurableAttributesDetails
                configurable_options={ configurable_options }
                areDetailsLoaded={ areDetailsLoaded }
                parameters={ parameters }
                product={ product }
            />
        </div>
    );
}

const renderConfigurableAttributes = (args, callback, instance) => {
    return (
        <>
            { instance.renderPopup() }
            { instance.renderDetails() }
        </>
    );
}

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderConfigurableAttributes,
            renderDetails,
            renderPopup
        }
    }
}