/* eslint-disable */

import './VariantSelectedFilter.style';

/** @namespace Component/VariantSelectedFilter/Component */
export class VariantSelectedFilter extends PureComponent {
    render() {
        const {
            filter: {
                label,
                value,
            },
            onClick
        } = this.props;

        return (
            <div
                block="VariantFilters"
                elem="SelectedFilter"
                onClick={ onClick }
            >
                <span
                    block="VariantFilters"
                    elem="SelectedFilterLabel"
                >
                    { label }:
                </span>
                <span
                    block="VariantFilters"
                    elem="SelectedFilterValue"
                >
                    { value }
                </span>
                <span
                    block="VariantFilters"
                    elem="SelectedFilterCloseButton"
                >
                    ✖
                </span>
            </div>
        );
    }
}

export default VariantSelectedFilter;