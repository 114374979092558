/* eslint-disable */
import ProductConfigurableAttributesDetails from './ProductConfigurableAttributesDetails.component';

/** @namespace Component/ProductConfigurableAttributesDetails/Container */
export class ProductConfigurableAttributesDetailsContainer extends PureComponent {
    containerFunctions = {
        getSubHeading: this.getSubHeading.bind(this)
    };

    getSubHeading({ attribute_values, attribute_code, attribute_options }) {
        return attribute_values.reduce((acc, attribute_value) => (
            this.isSelected({ attribute_code, attribute_value })
                ? [...acc, attribute_options[attribute_value].label]
                : acc
        ), []).join(', ');
    }

    isSelected({ attribute_code, attribute_value }) {
        const { parameters = {} } = this.props;
        const parameter = parameters[attribute_code];

        if (parameter === undefined) {
            return false;
        }
        if (parameter.length !== undefined) {
            return parameter.includes(attribute_value);
        }

        return parameter === attribute_value;
    }

    render() {
        return (
            <ProductConfigurableAttributesDetails
                { ...this.props }
                { ...this.containerFunctions }
            />
        );
    }
}

export default ProductConfigurableAttributesDetailsContainer;