// import PropTypes from 'prop-types';
import { STATE_CONFIRM_EMAIL } from '@scandipwa/scandipwa/src/component/MyAccountOverlay/MyAccountOverlay.config';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import history from 'Util/History';
import { getVariantIndex } from 'Util/Product';

import DiscountSign from './DiscountSign.component';

/** @namespace Hoeks/DiscountSign/Component/DiscountSign/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
    category: state.CategoryReducer.category,
    product: state.ProductReducer.product,
    breadcrumbs: state.BreadcrumbsReducer.breadcrumbs
});

/** @namespace Hoeks/DiscountSign/Component/DiscountSign/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Hoeks/DiscountSign/Component/DiscountSign/Container/DiscountSignContainer */
export class DiscountSignContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    state = {
        show: true
    }

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    componentDidMount() {
        const { category } = this.props;
        this.setShow();
    }

    componentDidUpdate() {
        const { category } = this.props;
        this.setShow();
    }

    setShow() {
        const { show } = this.props;
        var isVisibel = true;

        if (this.isOnCategoryPage()) {
            isVisibel = this.isVisibelOnCategory();
        }

        if (show != isVisibel) {
            this.setState({show: isVisibel})
        }
    }

    isOnCategoryPage() {
        const { category, breadcrumbs } = this.props;
        if (Object.keys(category).length === 0) return false;
        const { url } = category;

        return breadcrumbs.some(breadcrumb => breadcrumb.url === url);
    }

    isVisibelOnCategory() {
        const { category: { name } } = this.props;
        return name !== "Tilbud og IC Pris" && name !== "Tilbud";
    }

    render() {
        return (
            <DiscountSign
                {...this.state}
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscountSignContainer);
