/* eslint-disable */

// imports the original component from vendor folder
import SourcePostsDetails from 'scandipwa-blog/src/route/PostsDetails/PostsDetails.component';

// import dependencies
import ContentWrapper from 'Component/ContentWrapper';
import BlogCategories from 'scandipwa-blog/src/component/BlogCategories';
import BlogRecentPosts from 'scandipwa-blog/src/component/BlogRecentPosts';
import BlogSearchBar from 'scandipwa-blog/src/component/BlogSearchBar';
import BlogRelatedPosts from 'scandipwa-blog/src/component/BlogRelatedPosts';
import BlogRelatedProducts from 'scandipwa-blog/src/component/BlogRelatedProducts';
import Fade from 'react-reveal/Fade';
import Image from 'Component/Image';
import TextPlaceholder from 'Component/TextPlaceholder';
import { convertDateFormat } from 'scandipwa-blog/src/util/Date';
import Link from 'Component/Link';

// import new extended styles file
import './PostsDetails.override.style.scss';

export class PostsDetails extends SourcePostsDetails {

    renderTitle() {
        const { post: { title } } = this.props;

        return (
            <h1 mix={ { block: 'PostsDetails', elem: 'Title' } }>
                <TextPlaceholder length="medium" content={ title } />
            </h1>
        );
    }

    renderFeaturedImage() {
        const {
            post,
            post: {
                first_image
            }
        } = this.props;

        if (!post && post_id) {
            return null;
        }

        return (
            <Image
                block="PostsDetails"
                elem="FeaturedImage"
                src={ first_image }
            />
        );
    }

    renderPublishDate() {
        // const { post: { publish_time } } = this.props;

        // if (!publish_time) {
        //     return null;
        // }

        // return (
        //     <div mix={ { block: 'PostsDetails', elem: 'Date' } }>
        //         <TextPlaceholder
        //           mix={ { block: 'BlogRecentPosts', elem: 'DatePlaceholder' } }
        //           content={ __('- d. ') + convertDateFormat(publish_time) }
        //         />
        //     </div>
        // );

        return null;
    }

    renderAuthorName() {
        // const { post: { author } } = this.props;

        // if (!author) {
        //     return null;
        // }

        // return (
        //     <div mix={ { block: 'PostsDetails', elem: 'Author' } }>
        //         { __('by: ') }{ author.name }
        //     </div>
        // );

        return null;
    }

    render() {
        return ( 
            <main block="PostsDetails">
                <div
                    block="PostsDetails"
                    elem="Header"
                >
                    { this.renderFeaturedImage() }
                    <div 
                        block="PostsDetails"
                        elem="HeaderOverlay"
                    ></div>
                    <ContentWrapper
                        wrapperMix={ { block: 'PostsDetails', elem: 'HeaderWrapper' } }
                        label="Meta"
                    >
                        <div 
                            block="PostsDetails"
                            elem="HeaderContent"
                        >
                            <Link
                                block="PostsDetails"
                                elem="HeaderBackLink"
                                to="blog"
                            >
                                {/* <span
                                    block="PostsDetails"
                                    elem="HeaderBackLinkText"
                                >
                                    { __('Back to overview') }
                                </span> */}
                            </Link>
                            { this.renderTitle() }
                            <div 
                                block="PostsDetails"
                                elem="HeaderMeta"
                            >
                                { this.renderPublishDate() }
                                { this.renderAuthorName() }
                            </div>
                        </div>
                    </ContentWrapper>
                </div>
                <ContentWrapper
                  wrapperMix={ { block: 'PostsDetails', elem: 'Wrapper' } }
                  label="Post"
                >
                    <div block="PostsDetails" elem="ColumnWrapper">
                        <div block="PostsDetails" elem="Description">
                            { this.renderContent() }
                            <BlogRelatedProducts />
                            {/* <BlogRelatedPosts /> */}
                        </div>
                        <div block="PostsDetails" elem="Sidebar">
                            {/* <BlogSearchBar /> */}
                            {/* <BlogRecentPosts /> */}
                        </div> 
                    </div>
                </ContentWrapper>
            </main>
        );
    }

}

export default PostsDetails;
