/* eslint-disable */
import AddToWishlistButton from '../component/AddToWishlistButton';
import { Route } from 'react-router-dom';
import { SWITCH_ITEMS_TYPE } from 'Component/Router/Router.config';
import LocalWishlistPage from '../route/LocalWishlistPage';
import { WISHLIST_LOCATION } from '../store/LocalWishlist/LocalWishlist.config';

const productActionsComponentMemberFunctions = {
    renderProductWishlistButton: (args, callback, instance) => {
        return (
            <AddToWishlistButton
                { ...instance.props }
            />
        )
    }
}

const productCardComponentMemberFunctions = {
    renderProductCardWishlistButton: (args, callback, instance) => {
        return (
            <AddToWishlistButton
                { ...instance.props }
                mix={ { block: 'ProductCard', elem: 'WishListButton' } }
            />
        )
    }
}

const routerContainerMemberFunctions = {
    componentDidMount: (args, callback, instance) => {
        const result = callback.apply(instance, args);
        instance.localWishlistUpdate = instance.localWishlistUpdate.bind(instance);

        window.addEventListener('storage', instance.localWishlistUpdate);
        return result;
    },
    componentWillUnmount: (args, callback, instance) => {
        const result = callback.apply(instance, args);
        window.removeEventListener('storage', instance.localWishlistUpdate);
        return result;
    },
    localWishlistUpdate: (args, callback, instance) => {
        const [ e ] = args;
        const {
            key,
            newValue
        } = e;

        const {
            updateWishlist
        } = instance.props;

        if (key === WISHLIST_LOCATION) {
            const parsedNewValue = JSON.parse(newValue);

            const {
                data: newWishlist
            } = parsedNewValue;

            updateWishlist(newWishlist);
        }
    }
}

const LocalWishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/LocalWishlist/LocalWishlist.dispatcher'
);

const routerContainerMapDispatchToProps = (args, callback, instance) => {
    const [ dispatch ] = args;

    return {
        ...callback.apply(instance, args),
        updateWishlist: (wishlist) => LocalWishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateWishlist({ wishlist }, dispatch)
        )
    }
}

const routerComponentMemberProperties = {
    [SWITCH_ITEMS_TYPE]: (member, instance) => ([
        ...member,
        {
            component: <Route path={ '/wishlist' } render={ (props) => <LocalWishlistPage { ...props } /> } />,
            position: 200
        }
    ])
}

export default {
    'Component/ProductActions/Component': {
        'member-function': productActionsComponentMemberFunctions
    },
    'Component/ProductCard/Component': {
        'member-function': productCardComponentMemberFunctions
    },
    'Component/Router/Container': {
        'member-function': routerContainerMemberFunctions
    },
    'Component/Router/Container/mapDispatchToProps': {
        'function': routerContainerMapDispatchToProps
    },
    'Component/Router/Component': {
        'member-property': routerComponentMemberProperties
    }
}