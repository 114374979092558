// TODO update this import when renamed
import { LOCAL_WISHLIST_ADD_TO_WISHLIST, LOCAL_WISHLIST_REMOVE_FROM_WISHLIST, LOCAL_WISHLIST_UPDATE_WISHLIST } from './LocalWishlist.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { WISHLIST_LOCATION } from './LocalWishlist.config';

/** @namespace Store/LocalWishlist/Reducer/getInitialState */
export const getInitialState = () => ({
    wishlist: BrowserDatabase.getItem(WISHLIST_LOCATION) || []
});

/** @namespace Store/LocalWishlist/Reducer */
export const LocalWishlistReducer = (state = getInitialState(), action) => {
    const { payload, type } = action;

    switch (type) {
        case LOCAL_WISHLIST_ADD_TO_WISHLIST: {
            const {
                product: {
                    sku,
                    id
                }
            } = payload;
            const updatedWishlist = [ ...state.wishlist, { sku, id } ];
            BrowserDatabase.setItem(updatedWishlist, WISHLIST_LOCATION, 0);
            return {
                ...state,
                wishlist: updatedWishlist
            };
        }
        case LOCAL_WISHLIST_REMOVE_FROM_WISHLIST: {
            const {
                product: {
                    id: productId
                }
            } = payload;
            const updatedWishlist = [ ...state.wishlist.filter(({ id }) => id !== productId) ];
            console.log(updatedWishlist);
            BrowserDatabase.setItem(updatedWishlist, WISHLIST_LOCATION, 0);
            return {
                ...state,
                wishlist: updatedWishlist
            };
        }
        case LOCAL_WISHLIST_UPDATE_WISHLIST: {
            const {
                wishlist
            } = payload;
            return {
                ...state,
                wishlist
            };
        }
        default:
            return state;
    }
};

export default LocalWishlistReducer;
