/* eslint-disable */
import TextPlaceholder from 'Component/TextPlaceholder';
import { CONFIGURABLE } from 'Util/Product';

import './ProductConfigurableAttributesDetails.style';

/** @namespace Component/ProductConfigurableAttributesDetails/Component */
export class ProductConfigurableAttributesDetails extends PureComponent {
    renderWrapper(children) {
        return (
            <div
                block="ProductConfigurableAttributesDetails"
                elem="Wrapper"
            >
                { children }
            </div>
        );
    }

    renderPlaceholder() {
        return (
            <TextPlaceholder length="custom"/>
        );
    }

    renderConfigurableAttributesDetails() {
        const { configurable_options, getSubHeading } = this.props;

        return Object.values(configurable_options).map((option) => {
            const { attribute_code, attribute_label } = option;

            // bail if attribute not selected 
            if( !getSubHeading(option).length ) {
                return null;
            }

            return (
                <div
                    block="ProductConfigurableAttributesDetails"
                    elem="Attribute"
                    key={ attribute_code }
                >
                    <span
                        block="ProductConfigurableAttributesDetails"
                        elem="AttributeLabel"
                    >
                        { attribute_label }:
                    </span>
                    <span
                        block="ProductConfigurableAttributesDetails"
                        elem="AttributeValue"
                    >
                        { getSubHeading(option) }
                    </span>
                </div>
            );
        });
    }

    render() {
        const {
            areDetailsLoaded,
            product: { type_id }
        } = this.props;

        if (!areDetailsLoaded) {
            return this.renderWrapper(
                this.renderPlaceholder()
            );
        }

        if (type_id !== CONFIGURABLE) {
            return null;
        }

        return this.renderWrapper(
            this.renderConfigurableAttributesDetails()
        );
    }
}

export default ProductConfigurableAttributesDetails;