/* eslint-disable */
import { VariantProductList as SourceVariantProductList } from '@hoeks/simple-configurable-product/src/component/VariantProductList/VariantProductList.component'
import CmsBlock from 'Component/CmsBlock';
export * from '@hoeks/simple-configurable-product/src/component/VariantProductList/VariantProductList.component';

export class VariantProductList extends SourceVariantProductList {
    renderVariantMsg() {
        return (
            <div
                block="VariantProductList"
                elem="Msg"
            >
                <CmsBlock identifier="variant-popup-consulting-section" />
            </div>
        );
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return this.renderWrapper(
                this.renderLoader()
            );
        }

        return this.renderWrapper(
            <>
                { this.renderVariantQty() }
                { this.renderVariantProductList() }
                { this.renderVariantMsg() }
            </>
        );
    }
}

export default VariantProductList;