// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Link from 'Component/Link';

import './CategoryBlock.style';

/** @namespace Hoeks/Categoryblock/Component/CategoryBlock/Component/CategoryBlockComponent */
export class CategoryBlockComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderMenu() {
        const { groups } = this.props;
        return (
            <div block="CategoryBlock" elem="Menu">
                <ul block="CategoryBlock" elem="Menu-List">
                    {groups.length ? groups.map((group, i) => this.renderMenuItem(group, i)) : <></>}
                </ul>
            </div>
        );
    }

    renderMenuItem(group, i) {
        const { menuItemOnClick } = this.props;

        if (group == '') return;

        return (
            <li block="CategoryBlock" elem="Menu-List-Item" key={ i }><a onClick={() => menuItemOnClick(i)}><h3>{group}</h3></a></li>
        );
    }

    renderGroups() {
        const { groups } = this.props;
        return (
            <div block="CategoryBlock" elem="Groups">
                {groups.length ? groups.map((group, i) => this.renderGroup(group, i)) : <></>}
            </div>
        )
    }

    renderGroup(group, i) {
        const { subCategories } = this.props;
        const id = `category-group-${i}`;

        if (!subCategories.length || subCategories.length <= 0) {
            return (
                <div block="CategoryBlock" elem="Group" id={id} key={ id }>
                    <h2>{ group }</h2>
                    <ul block="CategoryBlock" elem="GroupList">
                    </ul>
                </div>
            )
        }

        const groupCategories = subCategories
        .filter(
            ({ group: categoryGroup }) => categoryGroup === group
        ).sort(
            ({ name: a }, { name: b }) => {
                const aUpper = a.toUpperCase();
                const bUpper = b.toUpperCase();

                if (aUpper < bUpper) return -1;
                if (aUpper > bUpper) return 1;
                return 0;
            }
        );

        return (
            <div block="CategoryBlock" elem="Group" id={id} key={ id }>
                <h2>{group}</h2>
                <ul block="CategoryBlock" elem="GroupList">
                    { groupCategories.map((category, i) => this.renderCategory(category, i)) }
                </ul>
            </div>
        );
    }

    renderCategory(category, i) {
        const { name, url } = category;

        if (!url) {
            return null;
        }

        return (
            <li
                block="CategoryBlock"
                elem="GroupItem"
                key={ i }
            >
                <Link to={ url }>
                    { name }
                </Link>
            </li>
        );
    }

    render() {
        return (
            <div block="CategoryBlock">
                {this.renderMenu()}
                {this.renderGroups()}
            </div>
        );
    }
}

export default CategoryBlockComponent;
